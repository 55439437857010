<ng-container *ngIf="ready && chatbot">
    <div id="chatbotbrc">
      <button class="btn-lg open-btn" (click)="openChatBot()">
         <div class="container-fluid">
            <div class="row d-flex flex-row">
               <div class="medium-2 small-12 p-2">
                  <img src="/assets/images/chatbot/chatbot.png" alt="Logo del Chatbot" title="Logo del Chatbot" width="40" height="40" class="lazyload">
               </div>
               <div class="medium-10 hide-for-small-only p-2 align-self-center d-none d-lg-block">
                  <span class="chatbot-btn-text text-center">PREGÚNTALE AL BIBLIOTECARIO</span>
               </div>
            </div>
         </div>
      </button>
      <ng-container *ngIf="chatWindow">
         <div class="chat-popup overflow-auto card" id="chatWindow">
            <div class="card-header">
                <div class="row d-flex flex-row">
                   <div class="medium-10 small-10 columns align-self-center d-none d-lg-block">
                      <span class="chatbot-title">PREGÚNTALE AL BIBLIOTECARIO</span>
                   </div>
                   <div class="medium-2 small-2 columns">
                     <button class="close-button" aria-label="Close alert" type="button" data-close (click)="closeChatBot()">
                       <span aria-hidden="true">&times;</span>
                     </button>
                   </div>
                </div>
            </div>
            <div class="card-body">
               <div class="chat-container">
                  <div id="captcha" class="container-fluid active">
                     <div class="captcha-container">
                        <div class="row">
                           <div class="medium-12 small-12 d-flex justify-content-center">
                              <span id="captchaError" class="captcha-error">* Este campo es obligatorio</span>
                           </div>
                        </div>
                        <div class="row">
                           <div class="medium-12 small-12 text-center">
                               <form [formGroup]="form">
                                 <re-captcha (resolved)="resolved($event)" formControlName="recaptchaReactive" [siteKey]="env?.chatbot?.siteKey">
                                 </re-captcha>
                                 <input type="submit" value="Search" class="hide"/>
                               </form>
                           </div>
                        </div>
                        <div class="row">
                           <div class="medium-12 small-12 d-flex justify-content-center">
                              <button id="captchaButton" class="chatbot-start-btn" (click)="startChatBot()">
                                 <div class="container">
                                    <div class="row">
                                       <div class="medium-2 medium-offset-2 small-2 small-offset-2 p-2">
                                           <img src="/assets/images/chatbot/chatbot.png" alt="Logo del Chatbot" title="Logo del Chatbot" width="40" height="40" class="lazyload">
                                       </div>
                                       <div class="medium-5 small-5 p-2 align-self-center">
                                          <span class="chatbot-btn-text align-middle">INICIAR CHAT</span>
                                       </div>
                                    </div>
                                 </div>
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="container-fluid" id="chatbot">
                     <div class="row">
                        <div class="medium-12 small-12">
                           <div id="webchat" role="main"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </ng-container>
    </div>
 </ng-container>