<div id="menu-citys" class="row" [ngClass]="option == 2? 'hide':''">
  <form [formGroup]="form" (ngSubmit)="find(form.value)">
    <label for="citys" class="title small-12">Biblioteca de tu ciudad</label>
    <div class="content-citys">
      <select id="citys" name="citys" [formControlName]="'city'" class="small-9" (change)="find(form.value)">
        <option *ngFor="let city of citys" [value]="city?.value" [selected]="city?.selected">{{city?.name}}</option>
      </select>
      <i class="icon"></i>
      <button type="submit" class="button small-3" aria-label="Buscar">IR</button>
    </div>
  </form>
</div>
