import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeekerService {

  private endpoint: string;

  constructor( private http: HttpClient ) {
    this.endpoint = environment.endpointApi + 'queue/slider-home';
  }

  Data(seccion:string): Observable<any> {
    this.endpoint = this.endpoint;
    return this.http.get(this.endpoint);
  }
}
