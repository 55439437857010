import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-menu-link',
  templateUrl: './menu-link.component.html'
})
export class MenuLinkComponent implements OnInit {

  @Input() item;
  @Input() class = '';
  @Input() role = '';
  @Input() mobile;
  @Input() language: string = 'es';

  constructor() { }

  ngOnInit(): void {}
}
