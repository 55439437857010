import { Component, Input, OnInit, AfterViewChecked } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SeekerService } from './seeker.service';
import { CommonFunctions } from '../../app.common';
import { translation } from '../../../assets/language/translation';
import { environment } from '../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-seeker',
  templateUrl: './seeker.component.html',
  providers: [ SeekerService ]
})
export class SeekerComponent implements OnInit, AfterViewChecked {

  @Input() home: string;
  @Input() city: string;
  @Input() place: string;
  @Input() language: string = 'es';
  public foundation: boolean;
  public active: boolean = false;
  public form: FormGroup;
  public filters = {header: [], collection: [], descubridor: []};
  public headerselectlabel: string;
  public collectionselectlabel: string;
  public config = {home: '', title: '', finder: '', type: 'default', active: false};
  public mobileSearch: string = 'general';
  public isMobile: boolean = false;
  public trans = translation;
  public env: any;

  constructor( private router: Router, private seekerService: SeekerService, private fb: FormBuilder, private commonFunctions: CommonFunctions ) { }

  ngOnInit(): void {
    this.env = environment;
    this.isMobile = this.commonFunctions.isMobile();
    this.form = new FormGroup({
      general: new FormControl(''),
      catalogo: new FormControl(''),
      virtual: new FormControl(''),
      descubridor: new FormControl(''),
      headerfinder: new FormControl(''),
      headerselect: new FormControl(''),
      collectionfinder: new FormControl(''),
      collectionselect: new FormControl(''),
    });

    this.filters = {
      header: [
        {
          id: 'all',
          value: 'all',
          name: 'headerselect',
          label: translation.seeker.header.options.all.label[this.language],
          active: false,
          visible: false,
        },
        {
          id: 'banrepcultural',
          value: 'banrepcultural',
          name: 'headerselect',
          label: translation.seeker.header.options.banrepcultural.label[this.language],
          active: false,
          visible: true,
        },
        {
          id: 'descubridor',
          value: 'descubridor',
          name: 'headerselect',
          label: translation.seeker.header.options.descubridor.label[this.language],
          active: false,
          visible: true,
        },
      ],
      collection: [
        {
          id: 'all',
          value: 'all',
          name: 'collectionselect',
          label: translation.seeker.collection.options.all.label[this.language],
          active: false,
          visible: false,
        },
        {
          id: 'general',
          value: 'general',
          name: 'collectionselect',
          label: translation.seeker.collection.options.general.label[this.language],
          active: false,
          visible: true,
        },
        {
          id: 'descubridor',
          value: 'descubridor',
          name: 'collectionselect',
          label: translation.seeker.collection.options.descubridor.label[this.language],
          active: false,
          visible: true,
        },
        {
          id: 'biblioteca',
          value: 'biblioteca',
          name: 'collectionselect',
          label: translation.seeker.collection.options.biblioteca.label[this.language],
          active: false,
          visible: false,
        },
        {
          id: 'biblioteca-virtual',
          value: 'biblioteca-virtual',
          name: 'collectionselect',
          label: translation.seeker.collection.options['biblioteca-virtual'].label[this.language],
          active: false,
          visible: false,
        },
        {
          id: 'recursos-electronicos',
          value: 'recursos-electronicos',
          name: 'collectionselect',
          label: translation.seeker.collection.options['recursos-electronicos'].label[this.language],
          active: false,
          visible: false,
        },
        {
          id: 'museos',
          value: 'museos',
          name: 'collectionselect',
          label: translation.seeker.collection.options.museos.label[this.language],
          active: false,
          visible: true,
        }
      ],
      descubridor: [
        {
          id: 'all',
          value: 'all',
          name: 'collectionselect',
          label: translation.seeker.descubridor.options.all.label[this.language],
          active: false,
          visible: false,
        },
        {
          id: 'descubridor',
          value: 'descubridor',
          name: 'collectionselect',
          label: translation.seeker.descubridor.options.descubridor.label[this.language],
          active: false,
          visible: true,
        },
        {
          id: 'biblioteca',
          value: 'biblioteca',
          name: 'collectionselect',
          label: translation.seeker.descubridor.options.biblioteca.label[this.language],
          active: false,
          visible: true,
        },
        {
          id: 'biblioteca-virtual',
          value: 'biblioteca-virtual',
          name: 'collectionselect',
          label: translation.seeker.descubridor.options['biblioteca-virtual'].label[this.language],
          active: false,
          visible: true,
        },
        {
          id: 'recursos-electronicos',
          value: 'recursos-electronicos',
          name: 'collectionselect',
          label: translation.seeker.descubridor.options['recursos-electronicos'].label[this.language],
          active: false,
          visible: true,
        },
        {
          id: 'museos',
          value: 'museos',
          name: 'collectionselect',
          label: translation.seeker.descubridor.options.museos.label[this.language],
          active: false,
          visible: false,
        }
      ]
    };

    this.headerselectlabel = translation.seeker.header.options.all.label[this.language];
    this.collectionselectlabel = translation.seeker.collection.options.all.label[this.language];

    switch (this.place) {
      case 'banrepcultural':
        this.config.home = 'banrepcultural';
        this.config.title = translation.seeker.config[this.place].title[this.language];
        this.config.active = true;
        this.form.controls['collectionselect'].setValue('descubridor');
        break;
      case 'centro-cultural':
        this.config.home = 'banrepcultural';
        this.config.title = translation.seeker.config[this.place].title[this.language];
        this.config.active = true;
        if(this.city === 'medellin'){
          this.config.home = 'museo-filatelico';
          this.config.finder = 'filatelica';
          this.config.title = translation.seeker.config['museo-filatelico'].title[this.language];
          this.config.type  = 'collection';
          this.config.active = this.env.colecciones.active;
          this.form.controls['collectionselect'].setValue('museo-filatelico');
        }
        break;
      case 'museo-del-oro-quimbaya':
      case 'museo-del-oro':
      case 'museo-del-oro-calima':
      case 'museo-del-oro-zenu':
      case 'museo-del-oro-nariño':
      case 'museo-del-oro-tairona':
        this.config.home = 'museo-del-oro';
        this.config.finder = 'arqueologica';
        this.config.title = translation.seeker.config[this.place].title[this.language];
        this.config.type  = 'collection';
        this.config.active = this.env.colecciones.active;
        this.form.controls['collectionselect'].setValue('museo-del-oro');
        break;
      case 'museo-botero':
        this.config.home = 'museo-botero';
        this.config.finder = 'botero';
        this.config.title = translation.seeker.config[this.place].title[this.language];
        this.config.type  = 'collection';
        this.config.active = this.env.colecciones.active;
        this.form.controls['collectionselect'].setValue('museo-botero');
        break;
      case 'museo-de-arte':
      case 'casa-gomez-campuzano':
        this.config.home = 'museo-de-arte';
        this.config.finder = 'arte';
        this.config.title = translation.seeker.config['museo-de-arte'].title[this.language];
        this.config.type  = 'collection';
        this.config.active = this.env.colecciones.active;
        this.form.controls['collectionselect'].setValue('museo-de-arte');
        break;
      case 'museo-etnografico':
      case 'casa-de-moneda':
      case 'actividad-musical':
        this.config.home = this.place;
        this.config.finder = 'billetes';
        this.config.title = translation.seeker.config[this.place].title[this.language];
        this.config.type  = 'collection';
        this.config.active = this.env.colecciones.active;
        this.form.controls['collectionselect'].setValue(this.place);
        if(this.place == 'museo-etnografico'){ this.config.finder = 'etnografica'; }
        if(this.place == 'casa-de-moneda'){ this.config.finder = 'billetes'; }
        if(this.place == 'actividad-musical'){ this.config.finder = 'instrumentos'; }
        break;
      case 'biblioteca':
      case 'biblioteca-luis-angel-arango':
        this.config.home = this.place;
        this.config.title = translation.seeker.config.biblioteca.title[this.language];
        this.config.active = true;
        this.form.controls['collectionselect'].setValue('descubridor');//this.place
        this.filters.collection[this.filters.descubridor.findIndex(c => c.id === 'descubridor')].active = true;
        this.collectionselectlabel = this.filters.descubridor[this.filters.descubridor.findIndex(c => c.id === 'descubridor')].label;
        break;
      case 'biblioteca-virtual':
        this.config.home = 'biblioteca-virtual';
        this.config.title = translation.seeker.config[this.place].title[this.language];
        this.config.active = true;
        this.config.type  = 'descubridor';
        this.form.controls['collectionselect'].setValue(this.place);
        this.filters.collection[this.filters.descubridor.findIndex(c => c.id === this.place)].active = true;
        this.collectionselectlabel = this.filters.descubridor[this.filters.descubridor.findIndex(c => c.id === this.place)].label;
        break;
      case 'servicio':
        this.config.home = 'servicio';
        this.config.title = translation.seeker.config[this.place].title[this.language];
        this.config.active = true;
        this.config.type  = 'descubridor';
        this.form.controls['collectionselect'].setValue('descubridor');
        this.filters.descubridor[this.filters.descubridor.findIndex(c => c.id === 'descubridor')].active = true;
        this.collectionselectlabel = this.filters.descubridor[this.filters.descubridor.findIndex(c => c.id === 'descubridor')].label;
        break;
      case 'recursos-electronicos':
        this.config.home = this.city +'-'+this.place;
        this.config.active = true;
        this.config.type  = 'descubridor';
        this.form.controls['collectionselect'].setValue('recursos-electronicos');
        this.filters.descubridor[this.filters.descubridor.findIndex(c => c.id === 'recursos-electronicos')].active = true;
        this.collectionselectlabel = this.filters.descubridor[this.filters.descubridor.findIndex(c => c.id === 'recursos-electronicos')].label;
        break;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.foundation) {
      this.foundation = true;
      $('app-seeker').foundation();

      $('#headerfinder').focusin(function(){
        $("label[for='headerfinder']").parent().addClass('has-value');
      });

      $('#headerfinder').blur(function(){
        if($('#headerfinder').val().length <= 0) {
          $("label[for='headerfinder']").parent().removeClass('has-value');
        }
        if($('#headerfinder').val().length > 0) {
          $("label[for='headerfinder']").parent().addClass('has-value');
        }
      });

      $('#headerfinder').keydown(function(e) {
        if(e.keyCode == 9 || e.keyCode == 37 || e.keyCode == 38 || e.keyCode == 39 || e.keyCode == 40) {// Tab or Arrows
          $('#header-finder .content-fieldset').addClass('active');
        }
      });
      $('#collectionfinder').keydown(function(e) {
        if(e.keyCode == 9 || e.keyCode == 37 || e.keyCode == 38 || e.keyCode == 39 || e.keyCode == 40) {// Tab or Arrows
          if($('#collection-finder .content-fieldset').length > 0){
            $('#collection-finder .content-fieldset').addClass('active');
          }
          if($('#descubridor-finder .content-fieldset').length > 0){
            $('#descubridor-finder .content-fieldset').addClass('active');
          }
        }
      });

      if($('#skrmbo').length > 0){
        $('#skrmbo').attr('role', 'menu');
      }
      if($('#skrmco').length > 0){
        $('#skrmco').attr('role', 'menu');
      }
      if($('#skrmdo').length > 0){
        $('#skrmdo').attr('role', 'menu');
      }
    }
  }

  find(value, tab) {
    var params = [];
    switch (tab) {
      case 'general': 
        this.router.navigate(['buscar'], {queryParams: {term: value.general}});
        break;
      case 'catalogo': 
        let txt = [{"char": " " , "value": "%20"}, {"char": "Á" , "value": "%C1"}, {"char": "É" , "value": "%C9"}, {"char": "Í" , "value": "%CD"}, {"char": "Ó" , "value": "%D3"}, {"char": "Ú" , "value": "%DA"}, {"char": "á" , "value": "%E1"}, {"char": "é" , "value": "%E9"}, {"char": "í" , "value": "%ED"}, {"char": "ó" , "value": "%F3"}, {"char": "ú" , "value": "%FA"}, {"char": "ñ" , "value": "%F1"}, {"char": "Ñ" , "value": "%D1"}, {"char": "!" , "value": "%21"}, {"char": "\"" , "value": "%22"}, {"char": "#" , "value": "%23"}, {"char": "$" , "value": "%24"}, {"char": "&" , "value": "%26"}, {"char": "'" , "value": "%27"}, {"char": "(" , "value": "%28"}, {"char": ")" , "value": "%29"}, {"char": "*" , "value": "%2A"}, {"char": "+" , "value": "%2B"}, {"char": "," , "value": "%2C"}, {"char": "-" , "value": "%2D"}, {"char": "." , "value": "%2E"}, {"char": "/" , "value": "%2F"}, {"char": ":" , "value": "%3A"}, {"char": ";" , "value": "%3B"}, {"char": "<" , "value": "%3C"}, {"char": "=" , "value": "%3D"}, {"char": ">" , "value": "%3E"}, {"char": "?" , "value": "%3F"}, {"char": ":" , "value": "%3A"}, {"char": ";" , "value": "%3B"}, {"char": "<" , "value": "%3C"}, {"char": "=" , "value": "%3D"}, {"char": ">" , "value": "%3E"}, {"char": "?" , "value": "%3F"}, {"char": "@" , "value": "%40"}, {"char": "Ç" , "value": "%C7"}, {"char": "ç" , "value": "%E7"}];
         for (var i = txt.length - 1; i >= 0; i--) {
          while (value.catalogo.toString().indexOf(txt[i].char) != -1) {
            value.catalogo = value.catalogo.toString().replace(txt[i].char,txt[i].value);
          }
        }
        window.open('http://ticuna.banrep.gov.co:8080/cgi-bin/abnetclwo20?ACC=DOSEARCH&xsqf10=' + value.catalogo + '', '_blank'); 
        break;
      case 'virtual':
        this.router.navigate(['/buscar'], {queryParams: {term: value.virtual, type: 'general', types: 'multimedia,collections'}});
        break;
      case 'descubridor': 
        params = new Array('query=any,contains,' + value.collectionfinder, 'tab=ALL', 'search_scope=MyInst_and_CI', 'vid=57BDLRDC_INST:57BDLRDC_INST', 'lang=es');
            window.open(this.env.descubridor.path + 'discovery/search?'+params.join('&'), this.env.descubridor.path.target);
        break;
      case 'biblioteca-virtual': 
        params = new Array('query=any,contains,' + value.collectionfinder, 'tab=Colecciones_Virtuales', 'search_scope=Colecciones_Bibliograficas', 'vid=57BDLRDC_INST:57BDLRDC_INST', 'lang=es');
            window.open(this.env.descubridor.path + 'discovery/search?'+params.join('&'), this.env.descubridor.path.target);
        break;
      case 'header':
        if(!value.headerfinder){
          $("#headerfinder").focus();
          return null;
        }
        switch (value.headerselect) {
          case "banrepcultural":
            this.router.navigate(['buscar'], {queryParams: {term: value.headerfinder}});
            break;
          case "descubridor":
            params = new Array('query=any,contains,' + value.headerfinder, 'tab=ALL', 'search_scope=MyInst_and_CI', 'vid=57BDLRDC_INST:57BDLRDC_INST', 'lang=es');
            window.open(this.env.descubridor.path + 'discovery/search?'+params.join('&'), this.env.descubridor.path.target);
            break;
          case "all":
            return false;
            break;
        }
        break;
      case 'default': 
      case 'collection':
        if(!value.collectionfinder){
          $("#collectionfinder").focus();
          return null;
        }
        switch (value.collectionselect) {
          case "general":
            this.router.navigate(['buscar'], {queryParams: {term: value.collectionfinder}});
            break;
          case "descubridor":
            params = new Array('query=any,contains,' + value.collectionfinder, 'tab=ALL', 'search_scope=MyInst_and_CI', 'vid=57BDLRDC_INST:57BDLRDC_INST', 'lang=es');
            window.open(this.env.descubridor.path + 'discovery/search?'+params.join('&'), this.env.descubridor.path.target);
            break;
          case "biblioteca":
            params = new Array('query=any,contains,' + value.collectionfinder, 'tab=Colecciones_fisicas', 'search_scope=LibraryCatalogOnly', 'vid=57BDLRDC_INST:57BDLRDC_INST', 'lang=es');
            window.open(this.env.descubridor.path + 'discovery/search?'+params.join('&'), this.env.descubridor.path.target);
            break;
          case "biblioteca-virtual":
            params = new Array('query=any,contains,' + value.collectionfinder, 'tab=Colecciones_Virtuales', 'search_scope=Colecciones_Bibliograficas', 'vid=57BDLRDC_INST:57BDLRDC_INST', 'lang=es');
            window.open(this.env.descubridor.path + 'discovery/search?'+params.join('&'), this.env.descubridor.path.target);
            break;
          case "recursos-electronicos":
            params = new Array('query=any,contains,' + value.collectionfinder, 'tab=Bases_de_Datos', 'search_scope=Bases_de_Datos_Externas', 'vid=57BDLRDC_INST:57BDLRDC_INST', 'lang=es');
            window.open(this.env.descubridor.path + 'discovery/search?'+params.join('&'), this.env.descubridor.path.target);
            break;
          case "museos":
            params = new Array('q=' + value.collectionfinder);
            window.open(this.env.colecciones.path + 'simple-recherche?'+params.join('&'), this.env.colecciones.path.target);
            break;
          case 'museo-del-oro':
            params = new Array('pageId=6357a765e27d753f221c6160', 'q=' + value.collectionfinder);
            window.open(this.env.colecciones.path + 'simple-recherche?'+params.join('&'), this.env.colecciones.path.target);
          break;
          case 'museo-de-arte':
            params = new Array('pageId=6357aa7ae27d753f221c618d', 'q=' + value.collectionfinder);
            window.open(this.env.colecciones.path + 'simple-recherche?'+params.join('&'), this.env.colecciones.path.target);
          break;
          case 'museo-etnografico':
            params = new Array('pageId=6357aaf7e27d753f221c619c', 'q=' + value.collectionfinder);
            window.open(this.env.colecciones.path + 'simple-recherche?'+params.join('&'), this.env.colecciones.path.target);
            break;
          case 'museo-filatelico':
            params = new Array('pageId=6357a986e27d753f221c617e', 'q=' + value.collectionfinder);
            window.open(this.env.colecciones.path + 'simple-recherche?'+params.join('&'), this.env.colecciones.path.target);
          break;
          case 'museo-botero':
            params = new Array('ubicacinActual%5B0%5D=Museo+Botero', 'pageId=6357aa7ae27d753f221c618d', 'q=' + value.collectionfinder);
            window.open(this.env.colecciones.path + 'simple-recherche?'+params.join('&'), this.env.colecciones.path.target);
            break;
          case 'casa-de-moneda':
            params = new Array('pageId=6357a7bae27d753f221c616f', 'q=' + value.collectionfinder);
            window.open(this.env.colecciones.path + 'simple-recherche?'+params.join('&'), this.env.colecciones.path.target);
            break;
          case 'actividad-musical':
            params = new Array('pageId=6357ab4de27d753f221c61a1', 'q=' + value.collectionfinder);
            window.open(this.env.colecciones.path + 'simple-recherche?'+params.join('&'), this.env.colecciones.path.target);
          break;
          case "all":
            return false;
            break;
        }
        break;
      case "advanced":
        if (!value.collectionfinder) {
          params = new Array('vid=57BDLRDC_INST:57BDLRDC_INST', 'lang=es', 'mode=advanced');
          window.open(this.env.descubridor.path + 'discovery/search?'+params.join('&'), this.env.descubridor.path.target);
        }
        switch (value.collectionselect) {
          case "descubridor":
            params = new Array('query=any,contains,' + value.collectionfinder, 'tab=ALL', 'search_scope=MyInst_and_CI', 'vid=57BDLRDC_INST:57BDLRDC_INST', 'lang=es', 'mode=advanced');
            window.open(this.env.descubridor.path + 'discovery/search?'+params.join('&'), this.env.descubridor.path.target);
            break;
          case "biblioteca":
            params = new Array('query=any,contains,' + value.collectionfinder, 'tab=Colecciones_fisicas', 'search_scope=LibraryCatalogOnly', 'vid=57BDLRDC_INST:57BDLRDC_INST', 'lang=es', 'mode=advanced');
            window.open(this.env.descubridor.path + 'discovery/search?'+params.join('&'), this.env.descubridor.path.target);
            break;
          case "biblioteca-virtual":
            params = new Array('query=any,contains,' + value.collectionfinder, 'tab=Colecciones_Virtuales', 'search_scope=Colecciones_Bibliograficas', 'vid=57BDLRDC_INST:57BDLRDC_INST', 'lang=es', 'mode=advanced');
            window.open(this.env.descubridor.path + 'discovery/search?'+params.join('&'), this.env.descubridor.path.target);
            break;
          case "recursos-electronicos":
            params = new Array('query=any,contains,' + value.collectionfinder, 'tab=Bases_de_Datos', 'search_scope=Bases_de_Datos_Externas', 'vid=57BDLRDC_INST:57BDLRDC_INST', 'lang=es', 'mode=advanced');
            window.open(this.env.descubridor.path + 'discovery/search?'+params.join('&'), this.env.descubridor.path.target);
            break;
          case "museos":
            return false;
            params = new Array('query=any,contains,' + value.collectionfinder, 'tab=Museos', 'search_scope=Museos', 'vid=57BDLRDC_INST:57BDLRDC_INST', 'lang=es', 'mode=advanced');
            window.open(this.env.descubridor.path + 'discovery/search?'+params.join('&'), this.env.descubridor.path.target);
            break;
          case "all":
            return false;
            break;
        }
        break;
    }
    return true;
  }

  change(type, value) {
    switch (type) {
      case "header":
        this.filters.header.forEach((v, k) => {
          this.filters.header[k].active = false;
        }, this);
        this.form.controls['headerselect'].setValue(this.filters.header[this.filters.header.findIndex(c => c.id === value)].value);
        this.filters.header[this.filters.header.findIndex(c => c.id === value)].active = true;
        this.headerselectlabel = this.filters.header[this.filters.header.findIndex(c => c.id === value)].label;
        break;
      case "collection":
        this.filters.collection.forEach((v, k) => {
          this.filters.collection[k].active = false;
        }, this);
        this.form.controls['collectionselect'].setValue(this.filters.collection[this.filters.collection.findIndex(c => c.id === value)].value);
        this.filters.collection[this.filters.collection.findIndex(c => c.id === value)].active = true;
        this.collectionselectlabel = this.filters[type][this.filters[type].findIndex(c => c.id === value)].label;
        break;
      case "descubridor":
        this.filters.descubridor.forEach((v, k) => {
          this.filters.descubridor[k].active = false;
        }, this);
        this.form.controls['collectionselect'].setValue(this.filters.collection[this.filters.descubridor.findIndex(c => c.id === value)].value);
        this.filters.collection[this.filters.descubridor.findIndex(c => c.id === value)].active = true;
        this.collectionselectlabel = this.filters[type][this.filters[type].findIndex(c => c.id === value)].label;
        break;
    }
  }

  handleMobileSearch(tab) {
    this.mobileSearch = tab
  }
}
