export const translation={
  "seeker": {
    "header": {
      "input": {
        "es": "Buscar",
        "en": "Search"
      },
      "label": {
        "es": "Buscar",
        "en": "Search"
      },
      "button": {
        "es": "Buscar",
        "en": "Search"
      },
      "options": {
        "all": {
          "label": {
            "es": "¿Dónde quieres buscar?",
            "en": "What would you like to find?"
          }
        },
        "banrepcultural": {
          "label": {
            "es": "Información general",
            "en": "General information"
          }
        },
        "descubridor": {
          "label": {
            "es": "Descubridor",
            "en": "Discoverer"
          }
        }
      }
    },
    "collection": {
      "input": {
        "es": "Ingresa el término de búsqueda",
        "en": "Enter the search term"
      },
      "label": {
        "es": "Buscar",
        "en": "Search"
      },
      "button": {
        "es": "Buscar",
        "en": "Search"
      },
      "options": {
        "all": {
          "label": {
            "es": "¿Dónde quieres buscar?",
            "en": "Choose where you want to search?"
          }
        },
        "general": {
          "label": {
            "es": "Información general",
            "en": "General information"
          }
        },
        "descubridor": {
          "label": {
            "es": "Descubridor",
            "en": "Discoverer"
          }
        },
        "biblioteca": {
          "label": {
            "es": "Colecciones físicas de bibliotecas",
            "en": "Library physical collections"
          }
        },
        "biblioteca-virtual": {
          "label": {
            "es": "Biblioteca Virtual",
            "en": "Virtual Library"
          }
        },
        "recursos-electronicos": {
          "label": {
            "es": "Recursos electrónicos y bases de datos",
            "en": "Electronic resources and databases"
          }
        },
        "museos": {
          "label": {
            "es": "Colecciones de Museos",
            "en": "Museum collections"
          }
        }
      }
    },
    "descubridor": {
      "input": {
        "es": "Buscar en todo el Descubridor",
        "en": "Search the entire Discoverer"
      },
      "label": {
        "es": "Buscar",
        "en": "Search"
      },
      "button": {
        "es": "Buscar",
        "en": "Search"
      },
      "options": {
        "all": {
          "label": {
            "es": "¿Dónde quieres buscar?",
            "en": "Choose where you want to search?"
          }
        },
        "descubridor": {
          "label": {
            "es": "Todo el Descubridor",
            "en": "All the Discoverer"
          }
        },
        "biblioteca": {
          "label": {
            "es": "Colecciones físicas de bibliotecas",
            "en": "Library physical collections"
          }
        },
        "biblioteca-virtual": {
          "label": {
            "es": "Biblioteca Virtual",
            "en": "Virtual Library"
          }
        },
        "recursos-electronicos": {
          "label": {
            "es": "Recursos electrónicos y bases de datos",
            "en": "Electronic resources and databases"
          }
        },
        "museos": {
          "label": {
            "es": "Colecciones de Museos",
            "en": "Museum collections"
          }
        }
      }
    },
    "mobile": {
      "general": {
        "es": "Información general",
        "en": "General information",
        "form": {
          "label": {
            "es": "Busca en nuestras páginas web",
            "en": "Search our web pages"
          },
          "input": {
            "es": "Ingresa el término de búsqueda",
            "en": "Enter the search term"
          },
          "button": {
            "es": "Buscar",
            "en": "Search"
          },
          "p": {
            "es": "Encuentra programación, noticias, colecciones digitales, servicios y mucho más…",
            "en": "Find programming, news, digital collections, services, and more…"
          }
        }
      },
      "catalogo": {
        "es": "Catálogo",
        "en": "Catálogo",
        "form": {
          "label": {
            "es": "Busca en el catálogo bibliográfico",
            "en": "Browse trough the bibliographic catalog"
          },
          "input": {
            "es": "Ingresa el término de búsqueda",
            "en": "Enter the search term"
          },
          "button": {
            "es": "Buscar",
            "en": "search"
          },
          "p": {
            "es": "Escribe el nombre del autor, libro o material bibliográfico que quieras consultar en nuestra Red de Bibliotecas",
            "en": "Write the name of the author, book or bibliographic material that you want to consult in our Library Network"
          }
        }
      },
      "descubridor": {
        "es": "Descubridor",
        "en": "Discoverer",
        "form": {
          "label": {
            "es": "Busca en nuestras colecciones bibliográficas físicas y digitales",
            "en": "Browse trough our physical and digital bibliographic collections"
          },
          "input": {
            "es": "Ingresa el término de búsqueda",
            "en": "Enter the search term"
          },
          "button": {
            "es": "Buscar",
            "en": "Search"
          },
          "p": {
            "es": "Encuentra programación, noticias, colecciones digitales, servicios y mucho más…",
            "en": "Find programming, news, digital collections, services, and more…"
          }
        }
      }
    },
    "config": {
      "banrepcultural": {
        "title": {
          "es": "Busca en nuestras páginas web o explora en nuestras colecciones",
          "en": "Search our web pages or explore our collections"
        }
      },
      "centro-cultural": {
        "title": {
          "es": "Busca en nuestras páginas web o explora en nuestras colecciones",
          "en": "Search our web pages or explore our collections"
        },
        "medellin": {
          "title": {
            "es": "Busca en la Colección de Estampillas",
            "en": "Browse trough the stamp collection"
          }
        }
      },
      "museo-del-oro-quimbaya": {
        "title": {
          "es": "Busca piezas en la Colección Arqueológica",
          "en": "Browse trough the Archaeological Collection"
        }
      },
      "museo-del-oro": {
        "title": {
          "es": "Busca piezas en la Colección Arqueológica",
          "en": "Browse trough the Archaeological Collection"
        }
      },
      "museo-del-oro-calima": {
        "title": {
          "es": "Busca piezas en la Colección Arqueológica",
          "en": "Browse trough the Archaeological Collection"
        }
      },
      "museo-del-oro-zenu": {
        "title": {
          "es": "Busca piezas en la Colección Arqueológica",
          "en": "Browse trough the Archaeological Collection"
        }
      },
      "museo-del-oro-nariño": {
        "title": {
          "es": "Busca piezas en la Colección Arqueológica",
          "en": "Browse trough the Archaeological Collection"
        }
      },
      "museo-del-oro-tairona": {
        "title": {
          "es": "Busca piezas en la Colección Arqueológica",
          "en": "Browse trough the Archaeological Collection"
        }
      },
      "museo-de-arte": {
        "title": {
          "es": "Busca obras de arte y artistas de la Colección de Arte",
          "en": "Browse trough artworks and artists from the Art Collection"
        }
      },
      "museo-etnografico": {
        "title": {
          "es": "Busca piezas de la Colección Etnográfica",
          "en": "Browse pieces from the ethnographic collection"
        }
      },
      "museo-filatelico": {
        "title": {
          "es": "Busca en la Colección de Estampillas",
          "en": "Browse trough the stamp collection"
        }
      },
      "biblioteca": {
        "title": {
          "es": "Busca en nuestras colecciones bibliográficas físicas y digitales",
          "en": "Search our web pages or explore our collections"
        }
      },
      "biblioteca-luis-angel-arango": {
        "title": {
          "es": "Busca en nuestras páginas web o explora en nuestras colecciones",
          "en": "Search our web pages or explore our collections"
        }
      },
      "biblioteca-virtual": {
        "title": {
          "es": "Busca libros digitales, revistas, interactivos",
          "en": "Search digital books, magazines, interactive"
        }
      },
      "casa-de-moneda": {
        "title": {
          "es": "Busca en la Colección de Billetes y Monedas",
          "en": "Browse trough the Banknotes and Coins Collection"
        }
      },
      "casa-gomez-campuzano": {
        "title": {
          "es": "Busca en la Colección de Arte",
          "en": "Browse trough artworks and artists from the Art Collection"
        }
      },
      "museo-botero": {
        "title": {
          "es": "Busca obras de arte del Museo Botero",
          "en": "Search for works of art from the Botero Museum"
        }
      },
      "actividad-musical": {
        "title": {
          "es": "Busca en la Colección de Instrumentos musicales",
          "en": "Browse trough the Musical Instruments Collection"
        }
      },
      "servicio": {
        "title": {
          "es": "Busca en nuestras colecciones bibliográficas físicas y digitales",
          "en": "Search our web pages or explore our collections"
        }
      }
    }
  },
  "activitys": {
    "h2": {
      "es": "Actividades",
      "en": "Events"
    },
    "form": {
      "formCitys": {
        "home_activities": {
          "label": {
            "es": "Escoge una ciudad",
            "en": "Location"
          }
        },
        "home_layout_activities": {
          "label": {
            "es": "Escoge una ciudad",
            "en": "Location"
          }
        },
        "detalle_activities": {
          "label": {
            "es": "Escoge una ciudad",
            "en": "Location"
          }
        },
        "tab_conciertos_activities": {
          "label": {
            "es": "Ciudad",
            "en": "City"
          }
        },
        "tab_conciertos_activities_bogota": {
          "label": {
            "es": "Ciudad",
            "en": "City"
          }
        },
        "home_actividad_musical_activities": {
          "label": {
            "es": "Escoge una ciudad",
            "en": "Location"
          }
        },
        "tags": {
          "label": {
            "es": "Escoge una ciudad",
            "en": "Location"
          }
        },
        "select": {
          "es": "Todas",
          "en": "All"
        }
      },
      "formTypes": {
        "label": {
          "es": "Tipo",
          "en": "Event type"
        },
        "select": {
          "es": "Todas",
          "en": "All"
        }
      },
      "formModality": {
        "label": {
          "es": "Modalidad",
          "en": "Mode"
        },
        "select": {
          "es": "Todas",
          "en": "All"
        }
      },
      "formMonths": {
        "label": {
          "es": "Mes",
          "en": "Month"
        },
        "select": {
          "es": "Todos",
          "en": "All"
        }
      },
      "formSeries": {
        "label": {
          "es": "Serie",
          "en": "Series"
        },
        "select": {
          "es": "Todas",
          "en": "All"
        }
      },
      "h2": {
        "tab_conciertos_activities": {
          "label": {
            "es": "Conciertos",
            "en": "Concert"
          }
        },
        "tab_conciertos_activities_bogota": {
          "label": {
            "es": "Conciertos en Bogotá",
            "en": "Concerts in Bogota"
          }
        }
      },
      "goto": {
        "es": "Ir al calendario",
        "en": "Go to calendar"
      },
      "noresults": {
        "es": "No hay actividades programadas",
        "en": "There´s no events"
      },
      "loadmore": {
        "es": "Cargar más",
        "en": "Load more"
      }
    },
    "filter": {
      "button": {
        "open": {
          "es": "Filtrar Actividades",
          "en": "Filter"
        },
        "close": {
          "es": "Cerrar",
          "en": "Close"
        },
        "submit": {
          "es": "Filtrar",
          "en": "Filter"
        }
      }
    }
  },
  "subscribe": {
    "h2": {
      "es": "Suscríbete a nuestras listas de correo",
      "en": "Subscribe to our newsletter"
    },
    "p": {
      "es": "y sé el primero en enterarte de todos nuestros eventos, exposiciones, noticias y más.",
      "en": "and be the first to hear about news, events, exhibitions and more"
    },
    "a": {
      "es": "FORMULARIO DE SUSCRIPCIÓN",
      "en": "FORM"
    }
  },
  "featured": {
    "banrepcultural": {
      "noticias": {
        "name": {
          "es": "Noticias",
          "en": "News"
        },
        "goto": {
          "es": "Leer todas las noticias",
          "en": "Read all the news"
        }
      },
      "recomendados": {
        "name": {
          "es": "Recomendados",
          "en": "Recommended"
        }
      },
      "para-ninos-y-jovenes": {
        "name": {
          "es": "Para niños y jovenes",
          "en": "For Kids"
        }
      },
      "proyectos": {
        "name": {
          "es": "Proyectos",
          "en": "Projects"
        }
      }
    },
    "biblioteca-virtual": {
      "proyectos": {
        "name": {
          "es": "Proyectos",
          "en": "Projects"
        }
      },
      "textos": {
        "name": {
          "es": "Textos",
          "en": "Texts"
        }
      },
      "tesis-y-articulos": {
        "name": {
          "es": "Tesis y artículos",
          "en": "Theses and articles"
        }
      },
      "revistas": {
        "name": {
          "es": "Revistas",
          "en": "Journals"
        }
      },
      "hemeroteca": {
        "name": {
          "es": "Hemeroteca",
          "en": "Newspaper library"
        }
      },
      "cartografia": {
        "name": {
          "es": "Cartografía",
          "en": "Maps"
        }
      },
      "imagenes": {
        "name": {
          "es": "Imágenes",
          "en": "Images"
        }
      },
      "noticias": {
        "name": {
          "es": "Noticias",
          "en": "News"
        }
      }
    },
    "actividad-musical": {
      "resenas": {
        "name": {
          "es": "Noticias, articulos y reseñas",
          "en": "News, articles and reviews"
        }
      },
      "proyectos-especiales": {
        "name": {
          "es": "Programas y convocatorias",
          "en": "Programs and call for bids "
        }
      },
      "publicaciones": {
        "name": {
          "es": "Publicaciones y colecciones",
          "en": "Publications and collections"
        }
      },
      "sala-de-musica": {
        "name": {
          "es": "Salas de Música",
          "en": "Music rooms"
        }
      },
      "sala-de-conciertos": {
        "name": {
          "es": "Sala de Conciertos de la BLAA",
          "en": "BLAA Concert Hall"
        }
      }
    },
    "ninos": {
      "recomendados": {
        "name": {
          "es": "Recomendados",
          "en": "Recommended"
        }
      },
      "noticias": {
        "name": {
          "es": "Noticias",
          "en": "News"
        }
      }
    },
    "biblioteca": {
      "recomendados": {
        "name": {
          "es": "Recomendados",
          "en": "Recommended"
        }
      }
    },
    "default": {
      "noticias": {
        "name": {
          "es": "Noticias",
          "en": "News"
        }
      },
      "recomendados": {
        "name": {
          "es": "Recomendados",
          "en": "Recommended"
        }
      }
    }
  },
  "museums": {
    "banrepcultural": {
      "title": {
        "es": "Visita las exposiciones actuales",
        "en": "Current exhibitions"
      },
      "aria": {
        "es": "Ir a todas las exposiciones actuales",
        "en": "Go to current exhibitions"
      },
      "label": {
        "es": "Todas las exposiciones actuales",
        "en": "Go to current exhibitions"
      }
    },
    "centro-cultural": {
      "title": {
        "es": "Exposiciones temporales",
        "en": "Temporary exhibitions"
      }
    },
    "museo-del-oro-quimbaya": {
      "title": {
        "es": "Exposiciones temporales",
        "en": "Temporary exhibitions"
      }
    },
    "biblioteca": {
      "title": {
        "es": "Contenidos destacados",
        "en": "Featured content"
      }
    },
    "biblioteca-luis-angel-arango": {
      "title": {
        "es": "Visita las exposiciones actuales",
        "en": "Current exhibitions"
      }
    },
    "biblioteca-virtual": {
      "title": {
        "es": "Para niños y jóvenes",
        "en": "Kids & teens"
      }
    },
    "museo-del-oro": {
      "title": {
        "es": "Contenidos destacados",
        "en": "Featured content"
      }
    },
    "museo-del-oro-calima": {
      "title": {
        "es": "Contenidos destacados",
        "en": "Featured content"
      }
    },
    "museo-del-oro-zenu": {
      "title": {
        "es": "Contenidos destacados",
        "en": "Featured content"
      }
    },
    "museo-del-oro-nariño": {
      "title": {
        "es": "Contenidos destacados",
        "en": "Featured content"
      }
    },
    "museo-del-oro-tairona": {
      "title": {
        "es": "Exposiciones temporales",
        "en": "Temporary exhibitions"
      }
    },
    "museo-etnografico": {
      "title": {
        "es": "Exposiciones temporales",
        "en": "Temporary exhibitions"
      }
    },
    "museo-de-arte": {
      "title": {
        "es": "Exposiciones temporales",
        "en": "Temporary exhibitions"
      }
    },
    "casa-de-moneda": {
      "title": {
        "es": "Exposición permanente",
        "en": "Permanent exhibition"
      }
    },
    "casa-gomez-campuzano": {
      "title": {
        "es": "Contenidos destacados",
        "en": "Featured content"
      }
    },
    "museo-botero": {
      "title": {
        "es": "Contenidos destacados",
        "en": "Featured content"
      }
    },
    "actividad-musical": {
      "title": {
        "es": "Contenidos destacados",
        "en": "Featured content"
      }
    },
    "ninos": {
      "title": {
        "es": "Juegos e interactivos",
        "en": "Games and interactive content"
      }
    },
    "servicios": {
      "title": {
        "es": "Nuestros recomendados",
        "en": "Our pick"
      }
    },
    "red-de-bibliotecas": {
      "title": {
        "es": "Recomendados de tu biblioteca",
        "en": "Recommended from your library"
      }
    },
    "owlcarousel": {
      "last": {
        "title": {
          "es": "Busca las exposiciones temporales en tu ciudad",
          "en": "Look for temporary exhibitions in your city"
        },
        "button": {
          "es": "CONSULTAR",
          "en": "GO"
        },
        "city": {
          "es": "Seleccione la ciudad",
          "en": "Choose location"
        }
      }
    }
  },
  "services": {
    "title": {
      "es": "Servicios ",
      "en": "Services "
    },
    "goto": {
      "es": "Ir a todos los servicios",
      "en": "All services"
    }
  },
  "association": {
    "h2": {
      "es": "Hazte socio de Banrepcultural",
      "en": "Become a member"
    },
    "p": {
      "es": "Y obtén préstamo y reserva de libros, actividades gratuitas, descuentos y muchos otros servicios.",
      "en": "And get book loan and reservation, free activities, discounts and many other services."
    },
    "a": {
      "es": "Conoce todos los beneficios",
      "en": "Know all the benefits"
    }
  },
  "multimedia": {
    "title": {
      "es": "Canal multimedia",
      "en": "Multemedia channel"
    },
    "goto": {
      "es": "Ir al canal",
      "en": "Go to channel"
    }
  },
  "footer": {
    "copyright": {
      "es": "Todos los derechos reservados.",
      "en": "All rights reserved."
    }
  }
};