<footer>
   <div class="row">
      <div class="medium-1 small-12 columns">
         <img class="lazyload" [attr.data-src]="'/assets/images/logo.png'" alt="La Red Cultural del Banco de la República" title="La Red Cultural del Banco de la República" [attr.longdesc]="'../#about-img-footer-logo'" width="101" height="99">
         <div [attr.id]="'about-img-footer-logo'" class="hide"><p>La Red Cultural del Banco de la República</p></div>
      </div>
      <div *ngIf="ready" class="medium-3 small-12 columns about">
         <ul>
            <ng-container *ngFor="let item of menu[0]?.childs; let f = first;">
              <ng-container *ngIf="f">
                <li>
                  <h2><app-menu-link [item]="item" [language]="language"></app-menu-link></h2>
                </li>
              </ng-container>
              <ng-container *ngIf="!f">
                <li>
                  <app-menu-link [item]="item" [language]="language"></app-menu-link>
                </li>
              </ng-container>
            </ng-container>
         </ul>
         <div class="social">
            <ul>
               <li><a [routerLink]="'/redes'" [fragment]="'facebook'" class="icfb">Banrepcultural en Facebook</a></li>
               <li><a [routerLink]="'/redes'" [fragment]="'youtube'" class="icyt">Banrepcultural en YouTube</a></li>
               <li><a [routerLink]="'/redes'" [fragment]="'instagram'" class="icig">Banrepcultural en Instagram</a></li>
               <li><a [routerLink]="'/redes'" [fragment]="'twitter'" class="ictw">Banrepcultural en Twitter</a></li>
            </ul>
         </div>
      </div>
      <div *ngIf="ready" class="medium-3 small-12 columns help hide-for-small-only">
        <h2>{{menu[1]?.name}}</h2>
        <ng-container *ngFor="let item of menu[1]?.childs; let f = first;">
          <div>
            <app-menu-link [item]="item" [language]="language"></app-menu-link>
          </div>
        </ng-container>
      </div>
      <div *ngIf="ready" class="medium-5 small-12 columns contact hide-for-small-only">
        <h2>{{menu[2]?.name}}</h2>
        <ng-container *ngFor="let item of menu[2]?.childs; let f = first;">
          <div>
            <app-menu-link [item]="item" [language]="language"></app-menu-link>
          </div>
        </ng-container>
      </div>
   </div>
   <div class="row copyright">
      <div class="medium-12 columns">© 2024 Banco de la República, Colombia. {{trans?.footer?.copyright[language]}}</div>
   </div>
</footer>

<app-chatbot></app-chatbot>

<app-cookies-policy></app-cookies-policy>
