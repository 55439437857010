import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonFunctions } from '../../app.common';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
	public endpoint: string;

	constructor( private http: HttpClient, private commonFunctions: CommonFunctions ) {
		this.endpoint = environment.endpointApi + 'brc/menu?';
	}

	getData(params: any): Observable<any> {
		return this.http.get(this.endpoint + this.commonFunctions.paramsEndPoint(params));
	}
}
