import { Component, ElementRef, OnInit, ViewChild, Renderer2, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
})
export class ChatbotComponent implements OnInit {
  
  public env: any;
  public form: FormGroup;
  public captchaResponse: any;
  public livehelperchat: boolean = true;
  public chatbot: boolean = false;
  public chatWindow: boolean = false;
  public ready: boolean = false;
  @ViewChild("webchat", { static: false }) botWindowElement: ElementRef;
  passViewChild: ViewChild;

  constructor(private _renderer2: Renderer2, @Inject(DOCUMENT) private _document) { }

  ngOnInit(): void {
    this.createForm();
    this.env = environment;
    this.livehelperchat = false;
    this.chatbot = true;
    this.ready = true;
  }

  createForm() {
    this.form = new FormGroup({
      recaptchaReactive: new FormControl(null, Validators.required),
    });
  }

  startChatBot() {
    if(typeof this.captchaResponse == 'undefined' || this.captchaResponse.length == 0) {
      document.getElementById('captchaError').style.display = 'block';
      $('#captchaError').addClass('active');
    } else {
      $('#captcha').removeClass('active');
      $('#captchaError').removeClass('active');
      $('#chatbot').addClass('active');
      let mbf = this._renderer2.createElement('script');
      mbf.type = `text/javascript`;
      mbf.text = 'function fontFamily(fonts) { return fonts.map(font => `"${font}"`).join(", "); } const styleOptions = { hideUploadButton: true, botAvatarInitials: "BT", bubbleBackground: "White", bubbleTextColor: "Black", bubbleFromUserBackground: "#a2bfd4", bubbleFromUserTextColor: "Black", bubbleBorderRadius: 8, bubbleFromUserBorderRadius: 8, fontSizeSmall: "80%", sendBoxHeight: 60, primaryFont: fontFamily(["blaa-light"]), botAvatarImage: "/assets/images/chatbot/mona.png", }; var theURL = "'+this.env.chatbot.endpoint+'"; const store = window.WebChat.createStore({}, ({ dispatch }) => next => action => { if(action.type === "DIRECT_LINE/CONNECT_FULFILLED") { dispatch({ meta: { method: "keyboard", }, payload: { activity: { channelData: { postBack: true, }, name: "startConversation", type: "event" }, }, type: "DIRECT_LINE/POST_ACTIVITY", }); } return next(action); }); fetch(theURL).then(response => response.json()).then(conversationInfo => { window.WebChat.renderWebChat({ directLine: window.WebChat.createDirectLine({ token: conversationInfo.token, }), store: store, styleOptions: styleOptions }, document.getElementById("webchat")); }).catch(err => console.error("An error occurred: " + err));';
      mbf.async = true;
      this._renderer2.appendChild(this._document.head, mbf);
    }
  }

  openChatBot() {
    this.chatWindow = true;
    let mbf = this._renderer2.createElement('script');
    mbf.type = `text/javascript`;
    mbf.src = `https://cdn.botframework.com/botframework-webchat/latest/webchat.js`;
    mbf.async = true;
    this._renderer2.appendChild(this._document.head, mbf);
  }

  closeChatBot() {
    this.chatWindow = false;
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    //console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  errored() {
    console.warn(`reCAPTCHA error encountered`);
  }

  /*insertLiveHelperChat() {
    let po = document.createElement('script');
    po.type = 'text/javascript';
    po.async = true;
    let referrer = (document.referrer) ? encodeURIComponent(document.referrer.substr(document.referrer.indexOf('://') + 1)) : '';
    let location = (document.location) ? encodeURIComponent(window.location.href.substring(window.location.protocol.length)) : '';
    let domain = 'https://admin.banrepcultural.org/chat/index.php';
    let base = '/esp/chat/getstatus/(click)/internal/(position)/bottom_right/(ma)/br/(top)/350/(units)/pixels/(leaveamessage)/true?r=';
    po.src = domain + base + referrer + '&l=' + location;
    this._renderer2.appendChild(this._document.body, po);
  }*/

  /*deleteLiveHelperChat() {
    if($('#lhc_container_v2').length !== 0) {
      $('#lhc_container_v2').remove()
    }
  }*/
}