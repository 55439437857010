<ng-container [ngSwitch]="home">
  <ng-container *ngSwitchCase="'buscador-movil'">
    <ul class="tabs row" data-deep-link="true" data-update-history="true" data-deep-link-smudge="true" data-tabs id="seeker-tabs">
        <li class="tabs-title medium-3 small-6 text-center" [ngClass]="mobileSearch == 'general' ? 'active is-active' : ''">
            <div class="tab-title" (click)="handleMobileSearch('general')"><i class="demo-icon icon-search"></i>{{trans?.seeker?.mobile?.general[language]}}</div>
        </li>
        <li class="tabs-title medium-3 small-6 end text-center" [ngClass]="{'active is-active': mobileSearch == 'descubridor'}">
            <div class="tab-title" (click)="handleMobileSearch('descubridor')"><i class="demo-icon icon-newspaper"></i>{{trans?.seeker?.mobile?.descubridor[language]}}</div>
        </li>
    </ul>
    <div class="tabs-content" data-tabs-content="seeker-tabs">
        <div class="tabs-panel" id="buscador-general" [ngClass]="{'is-active': mobileSearch == 'general'}">
            <form [formGroup]="form" id="form-general" (ngSubmit)="find(form.value, 'general')">
                <label for="bm-general" class="black">{{trans?.seeker?.mobile?.general?.form?.label[language]}}</label> 
                <div class="content-search">
                  <input id="bm-general" name="general" [formControlName]="'general'" type="search" [placeholder]="trans?.seeker?.mobile?.general?.form?.input[language]" class="medium-11 small-10" required>
                  <button type="submit" class="button medium-1 small-2" aria-label="Buscar"><i class="demo-icon icon-search"></i></button>
                </div>
                <p>{{trans?.seeker?.mobile?.general?.form?.p[language]}}</p> 
            </form>
        </div>
        <div class="tabs-panel" id="buscador-descubridor" [ngClass]="{'is-active': mobileSearch == 'descubridor'}">
            <form [formGroup]="form" id="form-descubridor" (ngSubmit)="find(form.value, 'descubridor')">
                <label for="bm-descubridor" class="black">{{trans?.seeker?.mobile?.descubridor?.form?.label[language]}}</label> 
                <div class="content-search">
                  <input id="bm-descubridor" name="descubridor" [formControlName]="'descubridor'" type="search" [placeholder]="trans?.seeker?.mobile?.descubridor?.form?.input[language]" class="medium-11 small-10" required>
                  <button type="submit" class="button medium-1 small-2" aria-label="Buscar"><i class="demo-icon icon-search"></i></button>
                </div>
                <p>{{trans?.seeker?.mobile?.descubridor?.form?.p[language]}}</p> 
            </form>
        </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'slider'">
    <div class="seeker-internal">
      <ul class="tabs" data-deep-link="true" data-update-history="true" data-deep-link-smudge="true" data-tabs id="seeker-tabs">
          <li *ngIf="!env?.descubridor?.active" class="tabs-title is-active medium-3 columns text-center">
              <a href="#buscador-general" aria-selected="true"><i class="demo-icon icon-search"></i>Información general</a>
          </li>
          <li *ngIf="!env?.descubridor?.active" class="tabs-title medium-3 columns end text-center">
              <a href="#buscador-catalogo"><i class="demo-icon icon-newspaper"></i>Catálogo</a>
          </li>
          <li *ngIf="env?.descubridor?.active" class="tabs-title is-active medium-3 columns text-center">
              <a href="#buscador-descubridor" aria-selected="true"><i class="demo-icon icon-search"></i>{{env?.descubridor?.name}}</a>
          </li>
      </ul>
      <div class="tabs-content" data-tabs-content="seeker-tabs">
          <div *ngIf="!env?.descubridor?.active" class="tabs-panel is-active medium-12 columns" id="buscador-general">
              <form [formGroup]="form" id="form-general" (ngSubmit)="find(form.value, 'general')">
                  <div class="row floating-label">
                    <label for="general" class="black">Busca en nuestras páginas web</label> 
                    <input id="general" name="general" [formControlName]="'general'" type="search" placeholder="Ingresa el término de búsqueda" class="medium-11" required>
                    <button type="submit" class="button medium-1" aria-label="Buscar"><i class="demo-icon icon-search"></i></button>
                    <p>Encuentra programación, noticias, colecciones digitales, servicios y mucho más…</p> 
                  </div>
              </form>
          </div>
          <div *ngIf="!env?.descubridor?.active" class="tabs-panel medium-12 columns" id="buscador-catalogo">
              <form [formGroup]="form" id="form-catalogo" (ngSubmit)="find(form.value, 'catalogo')">
                  <div class="row floating-label">
                    <label for="catalogo" class="black">Busca en el catálogo bibliográfico</label> 
                    <input id="catalogo" name="catalogo" [formControlName]="'catalogo'" type="search" placeholder="Ingresa el término de búsqueda" class="medium-11" required>
                    <button type="submit" class="button medium-1" aria-label="Buscar"><i class="demo-icon icon-search"></i></button>
                    <p>Escribe el nombre del autor, libro o material bibliográfico que quieras consultar en nuestra Red de Bibliotecas</p> 
                  </div>
              </form>
          </div>
          <div *ngIf="env?.descubridor?.active" class="tabs-panel is-active medium-12 columns" id="buscador-descubridor">
              <form [formGroup]="form" id="form-descubridor" (ngSubmit)="find(form.value, 'descubridor')">
                  <div class="row floating-label">
                    <label for="descubridor" class="black">Busca en nuestras colecciones bibliográficas físicas y digitales</label> 
                    <input id="descubridor" name="descubridor" [formControlName]="'descubridor'" type="search" placeholder="Ingresa el término de búsqueda" class="medium-11" required>
                    <button type="submit" class="button medium-1" aria-label="Buscar"><i class="demo-icon icon-search"></i></button>
                    <p>Encuentra programación, noticias, colecciones digitales, servicios y mucho más…</p> 
                  </div>
              </form>
          </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'biblioteca-virtual'">
    <div class="seeker-internal">
      <ul class="tabs" data-deep-link="true" data-update-history="true" data-deep-link-smudge="true" data-tabs id="seeker-tabs">
          <li *ngIf="!env?.descubridor?.active" class="tabs-title is-active medium-3 columns text-center">
              <a href="#buscador-general" aria-selected="true"><i class="demo-icon icon-search"></i>Colecciones digitales</a>
          </li>
          <li *ngIf="!env?.descubridor?.active" class="tabs-title medium-3 columns end text-center">
              <a href="#buscador-catalogo"><i class="demo-icon icon-newspaper"></i>Catálogo</a>
          </li>
          <li *ngIf="env?.descubridor?.active" class="tabs-title is-active medium-3 columns text-center">
              <a href="#buscador-descubridor" aria-selected="true"><i class="demo-icon icon-search"></i>{{env?.descubridor?.name}}</a>
          </li>
      </ul>
      <div class="tabs-content" data-tabs-content="seeker-tabs">
          <div *ngIf="!env?.descubridor?.active" class="tabs-panel is-active medium-12 columns" id="buscador-general">
              <form [formGroup]="form" id="form-virtual" (ngSubmit)="find(form.value, 'virtual')">
                  <div class="row floating-label">
                    <label for="virtual" class="black">Busca en las colecciones digitales de la Biblioteca Virtual</label> 
                    <input id="virtual" name="virtual" [formControlName]="'virtual'" type="search" placeholder="Ingresa el término de búsqueda" class="medium-11" required>
                    <button type="submit" class="button medium-1" aria-label="Buscar"><i class="demo-icon icon-search"></i></button>
                    <p>Encuentra libros, revistas, artículos, fotografías, obras de arte, piezas de las colecciones y mucho más…</p> 
                  </div>
              </form>
          </div>
          <div *ngIf="!env?.descubridor?.active" class="tabs-panel medium-12 columns" id="buscador-catalogo">
              <form [formGroup]="form" id="form-catalogo" (ngSubmit)="find(form.value, 'catalogo')">
                  <div class="row floating-label">
                    <label for="catalogo" class="black">Busca en el catálogo bibliográfico</label> 
                    <input id="catalogo" name="catalogo" [formControlName]="'catalogo'" type="search" placeholder="Ingresa el término de búsqueda" class="medium-11" required>
                    <button type="submit" class="button medium-1" aria-label="Buscar"><i class="demo-icon icon-search"></i></button>
                    <p>Escribe el nombre del autor, libro o material bibliográfico que quieras consultar en nuestra Red de Bibliotecas</p> 
                  </div>
              </form>
          </div>
          <div *ngIf="env?.descubridor?.active" class="tabs-panel is-active medium-12 columns" id="buscador-descubridor">
              <form [formGroup]="form" id="form-descubridor" (ngSubmit)="find(form.value, 'biblioteca-virtual')">
                  <div class="row floating-label">
                    <label for="descubridor" class="black">Busca en nuestras colecciones bibliográficas físicas y digitales</label> 
                    <input id="descubridor" name="descubridor" [formControlName]="'descubridor'" type="search" placeholder="Ingresa el término de búsqueda" class="medium-11" required>
                    <button type="submit" class="button medium-1" aria-label="Buscar"><i class="demo-icon icon-search"></i></button>
                    <p>Encuentra programación, noticias, colecciones digitales, servicios y mucho más…</p> 
                  </div>
              </form>
          </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'header'">
    <div *ngIf="language" id="header-finder">
      <form id="form-header-finder" [formGroup]="form" (ngSubmit)="find(form.value, 'header')">
        <div class="content-search">
          <input id="headerfinder" name="headerfinder" [formControlName]="'headerfinder'" type="search" [placeholder]="trans?.seeker?.header?.input[language]" class="small-6" required>
          <label for="headerfinder" class="black">{{trans?.seeker?.header?.label[language]}}</label>
          <div id="headerfindermenu" class="small-5">
            <fieldset>
              <legend>{{headerselectlabel}}</legend>
              <div class="content-fieldset">
                <ng-container *ngFor="let filter of filters?.header; let i = index;">
                  <ng-container *ngIf="filter?.visible">
                    <p>
                      <input type="radio" [id]="'header-'+filter?.id" [value]="filter?.value" [formControlName]="filter?.name" [checked]="filter?.active" (click)="change('header', filter?.id)" tabindex="0">
                      <label [for]="'header-'+filter?.id" [ngClass]="{'active': filter?.active}" (click)="change('header', filter?.id)">{{filter?.label}}</label>
                    </p>
                  </ng-container>
                </ng-container>
              </div>
            </fieldset>
          </div>
          <button type="submit" class="button small-1" [aria-label]="trans?.seeker?.header?.button[language]">{{trans?.seeker?.header?.button[language]}}</button>
        </div>
      </form>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'collection-finder'">
    <ng-container *ngIf="config?.active">
      <div id="collection-finder">
        <div class="row">
          <div class="medium-2 columns hide-for-small-only bg-img {{config?.home}}">
            <div class="bg"></div>
          </div>
          <div class="medium-10 small-12 columns bg-color">
            <div class="bg">
              <div class="row">
                <div class="medium-12 small-12 columns">
                  <form id="form-collection-finder" [class]="'fcf-'+config?.type+' '+config?.finder" [formGroup]="form" (ngSubmit)="find(form.value, config?.type)">
                      <label for="collectionfinder" class="black">{{config?.title}}</label> 
                      <div class="content-search">
                        <input id="collectionfinder" name="collectionfinder" [formControlName]="'collectionfinder'" type="search" [placeholder]="trans?.seeker?.collection?.input[language]" class="cfi" [ngClass]="{'medium-8 small-5': config?.type == 'default', 'small-10': config?.type == 'collection'}" required>
                        <ng-container *ngIf="config?.type == 'default'">
                          <div id="collectionfindermenu" class="medium-3 small-5">
                            <fieldset>
                              <legend>{{collectionselectlabel}}</legend>
                              <div class="content-fieldset">
                                <ng-container *ngFor="let filter of filters?.collection; let i = index;">
                                  <ng-container *ngIf="filter?.visible">
                                    <p>
                                      <input type="radio" [id]="'opt-'+filter?.id" [value]="filter?.value" [formControlName]="filter?.name" [checked]="filter?.active" (click)="change('collection', filter?.id)" tabindex="0">
                                      <label [for]="'opt-'+filter?.id" [ngClass]="{'active': filter?.active}" (click)="change('collection', filter?.id)">{{filter?.label}}</label>
                                    </p>
                                  </ng-container>
                                </ng-container>
                              </div>
                            </fieldset>
                          </div>
                        </ng-container>
                        <button type="submit" class="button medium-1 small-2" [aria-label]="trans?.seeker?.collection?.button[language]">{{trans?.seeker?.header?.button[language]}}</button>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'descubridor'">
    <div id="descubridor-finder">
      <div class="row">
        <div class="medium-12 small-12 columns bg-color {{config?.home}}">
          <div class="bg">
            <div class="row">
              <div class="medium-12 small-12 columns">
                <form id="form-descubridor" [class]="'fcf-'+config?.type" [formGroup]="form" (ngSubmit)="find(form.value, config?.type)">
                    <img src="/assets/images/descubridor.svg" alt="Logo del Descubridor" title="Logo del Descubridor" width="233"  height="53" class="logo">
                    <label for="collectionfinder" class="black hide">{{config?.title}}</label> 
                    <div class="content-search">
                      <input id="collectionfinder" name="collectionfinder" [formControlName]="'collectionfinder'" type="search" [placeholder]="trans?.seeker?.descubridor?.input[language]" class="cfi" [ngClass]="{'medium-7 small-5': config?.type == 'descubridor'}" required>
                      <ng-container *ngIf="config?.type == 'descubridor'">
                        <div id="collectionfindermenu" class="medium-2 small-5">
                          <div class="divider">/</div>
                          <fieldset>
                            <legend>{{collectionselectlabel}}</legend>
                            <div class="content-fieldset">
                              <ng-container *ngFor="let filter of filters?.descubridor; let i = index;">
                                <ng-container *ngIf="filter?.visible">
                                  <p>
                                    <input type="radio" [id]="'opt-'+filter?.id" [value]="filter?.value" [formControlName]="filter?.name" [checked]="filter?.active" (click)="change('descubridor', filter?.id)" tabindex="0">
                                    <label [for]="'opt-'+filter?.id" [ngClass]="{'active': filter?.active}" (click)="change('descubridor', filter?.id)">{{filter?.label}}</label>
                                  </p>
                                </ng-container>
                              </ng-container>
                            </div>
                          </fieldset>
                        </div>
                      </ng-container>
                      <button type="submit" class="button medium-1 small-2" [aria-label]="trans?.seeker?.descubridor?.button[language]">{{trans?.seeker?.header?.button[language]}}</button>
                      <div class="adv medium-2 hide-for-small-only" (click)="find(form.value, 'advanced')">BÚSQUEDA AVANZADA</div>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
