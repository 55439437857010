import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-cookies-policy',
  template: '<ng-container *ngIf="loadComponent"><ng-container *ngIf="!cookie"><div id="cookies-policy"><div class="row"><div class="medium-8 small-12 columns"><b>ACEPTACIÓN DE <em>COOKIES</em></b><br>Este sitio web utiliza cookies esenciales para optimizar la experiencia de usuario. Al aceptar nuestra <a href="https://www.banrep.gov.co/aviso-legal" target="_blank">política de <em>cookies</em></a>, el Banco de la República puede recopilar información sobre como utiliza nuestro sitio web, qué páginas visita, el tiempo que pasa en ellas y en general, recolección de estadísticas de navegación. Utilizaremos esta información para mejorar el funcionamiento y visualización del sitio web y, por ende, su experiencia en línea. No se circularán con terceros datos personales de los usuarios de este sitio web.</div><div class="medium-4 small-12 columns text-center"><button (click)="cookieAccept()">Aceptar y continuar</button><button class="not" (click)="cookieNotAccept()">No aceptar y continuar</button></div></div></div></ng-container></ng-container>',
  providers: [ CookieService ]
})
export class CookiesPolicyComponent implements OnInit {

  public loadComponent: boolean = false;
  public cookie: boolean = false;
  public domain: string = environment.domain;
  public path = '/';
  public secure:  boolean = true;

  constructor(private cookieService: CookieService) {}

  ngOnInit(): void {
    window.onload = () => {
      setTimeout(() => {
        this.loadComponent = true;
        if(!this.cookieService.check('BRC')) {
          this.cookie = false;
        }else {
          if(this.cookieService.get('BRC') === 'false') {
            this.cookie = true;      
            this.deleteScript();      
          }
          if(this.cookieService.get('BRC') === 'true') {
            this.cookie = true;
          }
        }
      }, 0);
    };
  }

  cookieAccept() {
    this.cookie = true;
    let expiry = new Date();
    expiry.setDate(expiry.getDate()+365);
    this.cookieService.set('BRC', 'true', expiry, this.path, this.domain, this.secure, 'Lax');
  }

  cookieNotAccept() {
    this.cookie = true;
    let expiry = new Date();
    expiry.setDate(expiry.getDate()+365);
    this.cookieService.set('BRC', 'false', expiry, this.path, this.domain, this.secure, 'Lax'); 
    this.deleteScript();
  }

  deleteScript(){
    setTimeout(() => {
      Object.keys(this.cookieService.getAll()).forEach(function(index){
        if(index.indexOf('BRC') !== 0) {
          this.cookieService.delete(index);
        }
      },this);
      let po = document.createElement('script');
      po.text = "gtag('consent', 'update', {analytics_storage: 'denied',ad_storage: 'denied'});";
      $('body').append(po);
    }, 1000);
  }
}