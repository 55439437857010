<h1 class="hide-h" role="heading" aria-level="1">Opps! Error 404</h1>
<div id="error404" class="row">
   <div class="medium-5 small-12 columns text-center">
      <div class="container-404">
         <i class="demo-icon icon-compass"></i>
         <div class="error">Opps! <strong>Error 404</strong></div>
         <div class="alerta"><strong>Discúlpanos. </strong>Al parecer has llegado a una página que no existe.
         </div>
      </div>
   </div>
   <div class="medium-7 small-12 columns">
      <div class="enlaces">
         <div class="mensaje">
            Puedes intentar de nuevo utilizando el buscador, o si prefieres, en los enlaces a continuación:
         </div>
         <ul>
            <li>
               <a [href]="env?.descubridor?.path" [target]="env?.descubridor?.target" rel="noopener">{{env?.descubridor?.name}}</a>
            </li>
            <li>
               <a href="http://babel.banrepcultural.org" target="_blank">Colecciones digitales de la Biblioteca Virtual</a>
            </li>
            <li>
               <a href="https://enciclopedia.banrepcultural.org" target="_blank">La enciclopedia (antes Ayuda de tareas)</a>
            </li>
            <li>
               <a href="https://publicaciones.banrepcultural.org/index.php/boletin_cultural" target="_blank">Boletín Cultural y Bibliográfico</a>
            </li>
            <li>
               <a href="/coleccion-de-arte" target="_blank">Colección de Arte del Banco de la República</a>
            </li>
            <li>
               <a href="https://publicaciones.banrepcultural.org/" target="_blank">Publicaciones periódicas del Banco de la República</a>
            </li>
            <li>
               <a href="https://proyectos.banrepcultural.org/proyecto-paz" target="_blank">La paz se toma la palabra</a>
            </li>
         </ul>
      </div>
   </div>
   <div class="medium-12 columns buscador">
      <app-seeker [home]="'collection-finder'" [place]="'banrepcultural'" [language]="'es'"></app-seeker>
   </div>
</div>