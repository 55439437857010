import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class MetatagsService {

  constructor(private titleService: Title, private meta: Meta) { }

  defaultMetaTags() {
    let description = this.stripHtmlTags(environment.description);
    
    this.titleService.setTitle(environment.title);
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ name: 'keywords', content: environment.keywords });
    this.meta.updateTag({ name: 'news_keywords', content: environment.keywords });
    this.meta.updateTag({ name: 'author', content: environment.author });
    this.meta.updateTag({ name: 'language', content: 'spanish' });
    $('link[rel="canonical"]').attr("href", environment.path);

    this.meta.updateTag({ property: 'og:title', content: environment.title });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'og:image', content: environment.image });
    this.meta.updateTag({ property: 'og:image:width', content: '600' });
    this.meta.updateTag({ property: 'og:image:height', content: '400' });
    this.meta.updateTag({ property: 'og:url', content: environment.path });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:locale', content: 'es_la' });

    this.meta.updateTag({ name: 'twitter:title', content: environment.title });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:image', content: environment.image });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:site', content: '@banrepcultural' });
  }

  updateMetaTags(node) {
    let title = environment.title, description = this.stripHtmlTags(environment.description), keywords = environment.keywords, image = environment.site + environment.image, path = environment.path;
    title = node.title ? node.title + ' | ' + title : title;

    if(typeof node.body_unsafe !== 'undefined' && node.body_unsafe !=  null){
      description = node.body_unsafe ? this.stripHtmlTags(node.body_unsafe) + '...' : description;
    }else{
      description = node.body ? this.stripHtmlTags(node.body) + '...' : description;
    }

    if(typeof node.image !== 'undefined'){
      if(typeof node.image.src !== 'undefined'){
        image =  node.image.src;
      }
    }

    if(typeof node.tags !== 'undefined' && node.tags !=  null){
      keywords = node.tags ? this.stripHtmlTags(node.tags.toString()) : keywords;
    }else{
      keywords = node.keywords ? node.keywords : keywords;
    }

    path = node.path ? path + node.path : path;

    this.titleService.setTitle(title );
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'news_keywords', content: keywords });
    this.meta.updateTag({ name: 'author', content: environment.author });
    this.meta.updateTag({ name: 'language', content: 'spanish' });
    $('link[rel="canonical"]').attr("href", path);

    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ property: 'og:image:width', content: '600' });
    this.meta.updateTag({ property: 'og:image:height', content: '400' });
    this.meta.updateTag({ property: 'og:url', content: path });
    this.meta.updateTag({ property: 'og:type', content: 'website' });

    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:image', content: image });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:site', content: '@banrepcultural' });
  }

  public stripHtmlTags(str) {
    if ((str===null) || (str===''))
      return false;
    else
      return str.toString().replace(/(?:\r\n|\r|\n)/g, ' ').replace(/<[^>]*>/g, '').substring(0, 227);
  }
}
