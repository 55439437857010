export const environment = {
	production: true,
	domain: 'www.banrepcultural.org',
	site: 'https://www.banrepcultural.org',
	author: 'Subgerencia Cultural del Banco de la República',
	title: 'La Red Cultural del Banco de la República',
	description : 'La red cultural del Banco de la República ofrece programación y servicios de acceso gratuito en sus Museos, Bibliotecas y Centros culturales en 29 ciudades del país.',
	keywords: 'Red Cultural, Banco de la República, Colombia, Patrimonio Cultural, Museo del Oro, Bibliotecas, Luis Ángel Arango',
	image: '/assets/images/banrepcultural.jpg',
	path : 'https://www.banrepcultural.org',
	endpoint: 'https://admin.banrepcultural.org/',
	endpointApi: 'https://admin.banrepcultural.org/api/',
	endpointTaxonomy: 'https://admin.banrepcultural.org/api/taxonomy/',
	endpointApiElastic: 'https://admin.banrepcultural.org/api/search?',
	assets: 'https://d3nmwx7scpuzgc.cloudfront.net/',
	assetssubdomain: 'cdnback',
	colecciones: {name: 'Colecciones', path: 'https://colecciones.banrepcultural.org/', target: '_blank', active: true},
	coleccion_arte: {name: 'Colección de Arte', path: 'https://colecciones.banrepcultural.org/page/coleccin-de-arte/6357aa7ae27d753f221c618d', target: '_blank', active: true},
	coleccion_arqueologica: {name: 'Colección Arqueológica', path: 'https://colecciones.banrepcultural.org/page/coleccin-arqueolgica-de-los-museos-del-oro/6357a765e27d753f221c6160', target: '_blank', active: true},
	coleccion_bibliografica: {name: 'Colección Bibliográfica y Documental', path: '/coleccion-bibliografica', target: '_self', active: true},
	coleccion_economica: {name: 'Colección Económica y financiera', path: '', target: '_blank', active: false},
	coleccion_etnografica: {name: 'Colección Etnográfica', path: 'https://colecciones.banrepcultural.org/page/coleccin-del-museo-etnogrfico/6357aaf7e27d753f221c619c', target: '_blank', active: true},
	coleccion_filatelica: {name: 'Colección Filatélica (Estampillas)', path:'https://colecciones.banrepcultural.org/page/coleccin-filatlica-estampillas/6357a986e27d753f221c617e', target: '_blank', active: true},
	coleccion_instrumentos: {name: 'Colección de Instrumentos', path: 'https://colecciones.banrepcultural.org/page/instrumentos-musicales/6357ab4de27d753f221c61a1', target: '_blank', active: true},
	coleccion_numismatica: {name: 'Colección Numismática (Billetes y monedas)', path: 'https://colecciones.banrepcultural.org/page/coleccin-numismtica-billetes-y-monedas/6357a7bae27d753f221c616f', target: '_blank', active: true},
	coleccion_botero: {name: 'Colección en Museo Botero', path: 'https://colecciones.banrepcultural.org/page/coleccin-de-arte/6357aa7ae27d753f221c618d?ubicacinActual%5B0%5D=Museo%20Botero', target: '_blank', active: true},
	coleccion_casa_gomez: {name: 'Casa Gómez Campuzano', path: 'https://colecciones.banrepcultural.org/simple-recherche?pageId=6357aa7ae27d753f221c618d&q=Ricardo%20G%C3%B3mez%20Campuzano&artista%5B0%5D=G%C3%B3mez%20Campuzano,%20Ricardo', target: '_blank', active: true},
	descubridor: {name: 'Descubridor', path: 'https://descubridor.banrepcultural.org/', target: '_blank', active: true},
	chatbot: {
		version: 'IA',
		endpoint: "https://d2cb7ea5ac15ebac86391d43ab5279.09.environment.api.powerplatform.com/powervirtualagents/botsbyschema/cr6dd_bibliotecarioPruebas/directline/token?api-version=2022-03-01-preview",
		siteKey: "6LfygisjAAAAADbeo0JmnN9gRtB35n-G0Wv7Lcmf"
	},
	matomoConfig: {
		scriptUrl: 'https://matomo-dev.demodayscript.com/matomo.js',
		trackers: [{
			trackerUrl: 'https://matomo-dev.demodayscript.com/matomo.php',
			siteId: 1
		}]
	}
};
