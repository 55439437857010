<div *ngIf="ready && !isMobile" id="mainmenu">
   <div class="row">
      <div class="large-12 columns">
         <nav aria-label="Menú principal">
            <ul class="dropdown menu normal" data-dropdown-menu role="menubar" aria-label="Menú principal">
               <ng-container *ngIf="menu">
                  <ng-container *ngFor="let item of menu ; let i = index; let f = first; let l = last;">
                   <ng-container *ngIf="item?.enabled">
                      <li role="none" data-is-click="false">
                        <ng-container *ngIf="f">
                          <a [routerLink]="url" [fragment]="'inicio'" target="_self" [title]="item?.name" [class]="item?.class" role="menuitem" aria-haspopup="true" aria-expanded="false"><div class="menu-icon"></div>{{item?.name}}</a>
                        </ng-container>
                        <ng-container *ngIf="!f">
                          <app-menu-link [item]="item" [role]="'menuitem'" [language]="language"></app-menu-link>
                        </ng-container>
                        <ng-container *ngIf="item?.childs && item?.childs?.length > 0">
                          <ul class="menu normal columns {{item?.id}}" [ngClass]="{'medium-8': f, 'medium-12': !f, 'end': l}" [attr.aria-label]="'Menú '+item?.name">
                            <ng-container *ngFor="let child of item?.childs ; let ic = index; let ec = even; let oc = odd; let fc = first; let lc = last;">
                              <ng-container *ngIf="child?.enabled">
                                <ng-container [ngSwitch]="i">
                                  <ng-container *ngSwitchCase="'0'">
                                    <li class="columns" [ngClass]="{'medium-12': fc, 'medium-6 pl': !fc, 'm-border-right': oc, 'end': lc}">
                                      <app-menu-link [item]="child" [role]="'menuitem'" [language]="language"></app-menu-link>
                                    </li>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'1'">
                                    <li class="medium-2 columns" [ngClass]="{'end': lc}">
                                      <app-menu-link [item]="child" [role]="'menuitem'" [language]="language"></app-menu-link>
                                    </li>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'2'">
                                    <li class="medium-4 columns" [ngClass]="{'m-border-right': !lc}">
                                      <app-menu-link [item]="child" [role]="'menuitem'" [language]="language"></app-menu-link>
                                      <div class="row">
                                         <ng-container *ngFor="let c of child?.childs; let icc = index; let fcc = first; let lcc = last">
                                          <ng-container [ngSwitch]="icc">
                                            <ng-container *ngSwitchCase="'0'">
                                              <div class="columns" [ngClass]="{'medium-6': !fc && !lc, 'medium-12': fc || lc}">
                                                <app-menu-link [item]="c" [role]="'menuitem'" [language]="language"></app-menu-link>
                                              </div>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                              <ng-container *ngIf="c?.image">
                                                <div class="columns" [ngClass]="{'medium-6': !lc, 'medium-12 ': lc}">
                                                  <app-menu-link [item]="c" [class]="mim" [role]="'menuitem'" [language]="language"></app-menu-link>
                                                </div>
                                              </ng-container>
                                              <ng-container *ngIf="!c?.image">
                                                <div class="columns" [ngClass]="{'medium-6': !lc, 'medium-10 medium-offset-2 text-center': lc && lcc}">
                                                  <app-menu-link [item]="c" [class]="lc && lcc ? '' : 'pdb-0'" [role]="'menuitem'" [language]="language"></app-menu-link>
                                                </div>
                                              </ng-container>
                                            </ng-container>
                                          </ng-container>
                                        </ng-container>
                                      </div>
                                    </li>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'3'">
                                    <ng-container *ngIf="ic<2">
                                      <li class="medium-3 columns mc-df">
                                        <app-menu-link [item]="child" [role]="'menuitem'" [language]="language"></app-menu-link>
                                        <div class="row">
                                          <ng-container *ngFor="let c of child?.childs">
                                            <div class="medium-12 columns">
                                              <app-menu-link [item]="c" [role]="'menuitem'" [language]="language"></app-menu-link>
                                            </div>
                                          </ng-container>
                                        </div>
                                      </li>
                                    </ng-container>
                                    <ng-container *ngIf="ic==2">
                                      <li class="columns medium-5 medium-offset-1"><app-menu-seeker></app-menu-seeker></li>
                                      <li class="columns medium-5 medium-offset-1"><app-menu-citys [nodes]="child?.childs"></app-menu-citys></li>
                                    </ng-container>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'5'">
                                    <li class="medium-3 columns pd-0" [ngClass]="{'end': lc}">
                                      <app-menu-link [item]="child" [role]="'menuitem'" [language]="language"></app-menu-link>
                                    </li>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'6'">
                                    <li class="medium-12 columns">
                                      <ng-container *ngIf="!fc"><hr></ng-container>
                                      <app-menu-link [item]="child" [role]="'menuitem'" [language]="language"></app-menu-link>
                                      <div class="row">
                                         <ng-container *ngFor="let c of child?.childs">
                                          <div class="medium-3 columns">
                                            <app-menu-link [item]="c" [role]="'menuitem'" [language]="language"></app-menu-link>
                                          </div>
                                        </ng-container>
                                      </div>
                                    </li>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'7'">
                                    <li class="columns" [ngClass]="{'medium-4': fc, 'medium-8': !fc}">
                                      <app-menu-link [item]="child" [role]="'menuitem'" [language]="language"></app-menu-link>
                                      <div class="row">
                                         <ng-container *ngFor="let c of child?.childs">
                                          <div class="columns" [ngClass]="{'medium-12 m-border-right': fc, 'medium-6': !fc}">
                                            <app-menu-link [item]="c" [role]="'menuitem'" [language]="language"></app-menu-link>
                                          </div>
                                        </ng-container>
                                      </div>
                                    </li>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'8'">
                                    <li class="medium-3 columns" [ngClass]="{'m-border-right': !lc}">
                                      <app-menu-link [item]="child" [role]="'menuitem'" [language]="language"></app-menu-link>
                                    </li>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                          </ul>
                        </ng-container>
                      </li>
                    </ng-container>
                 </ng-container>
               </ng-container>
               <ng-container *ngIf="search">
                 <li id="main-item-search" [ngClass]="searchActive ? 'is-active' : ''">
                  <button data-toggle="mega-menu" class="main-item main-item-search" tabindex="0" role="button" aria-label="Buscar" (click)="goToFinder()"><i class="demo-icon icon-search"></i></button>
                  <ng-container *ngIf="searchActive">
                    <ng-container *ngTemplateOutlet="searchMenu"></ng-container>
                  </ng-container>
                </li>
               </ng-container>
            </ul>
         </nav>
      </div>
   </div>
</div>
<div *ngIf="isMobile" id="mainmenuresp">
   <div class="mmr-4">
     <div class="title-bar" data-responsive-toggle="menu-resp">    
       <button class="menu-icon" type="button" data-toggle aria-label="Menú"></button>
       <button class="icon-cancel hide" type="button"></button>  
     </div>
   </div>
</div>
<div *ngIf="ready && isMobile" id="menu-resp" class="hide">
  <ul class="vertical menu accordion-menu" data-accordion-menu>
    <ng-container *ngFor="let item of menu; let i = index;">
      <li>
        <ng-container *ngIf="item?.childs?.length > 0"><a href="#">{{item?.name}}</a></ng-container>
        <ng-container *ngIf="item?.childs?.length < 1"><app-menu-link [item]="item" [mobile]="true" [role]="'menuitem'" [language]="language"></app-menu-link></ng-container>
        <ng-container *ngIf="item?.childs && item?.childs?.length > 0">
          <ul class="menu vertical nested {{item?.id}}">
             <ng-container *ngFor="let child of item?.childs; let j = index;">
                <ng-container *ngIf="child?.childs?.length < 1">
                  <li>
                    <app-menu-link [item]="child" [mobile]="true" [role]="'menuitem'" [language]="language"></app-menu-link>
                  </li>
                </ng-container>
                <ng-container *ngIf="i==3; else flow">
                  <ng-container *ngIf="j<2">
                    <ng-container *ngFor="let subchild of child?.childs">
                      <li>
                        <app-menu-link [item]="subchild" [mobile]="true" [role]="'menuitem'" [language]="language"></app-menu-link>
                      </li>
                   </ng-container>
                  </ng-container>  
                </ng-container>
                <ng-template #flow>
                  <ng-container *ngFor="let subchild of child?.childs">
                    <li>
                      <app-menu-link [item]="subchild" [mobile]="true" [role]="'menuitem'" [language]="language"></app-menu-link>
                    </li>
                 </ng-container>
                </ng-template>
             </ng-container>
          </ul>
          <ng-container *ngIf="i==3">
            <app-menu-seeker [option]="2"></app-menu-seeker>
            <app-menu-citys [option]="2" [nodes]="menu[i]?.childs[2]?.childs"></app-menu-citys>
          </ng-container>
        </ng-container>
      </li>
    </ng-container>
  </ul>
  <div class="small-12 columns association">
    <a [routerlink]="'/asociacion'" aria-label="Hazte socio" class="button" href="/asociacion">Hazte socio</a>
  </div>
  <div class="small-12 columns submenu">
    <ul *ngIf="readymh">
      <ng-container *ngFor="let item of menuheader">
        <li>
          <app-menu-link [item]="item" [role]="'menuitem'" [language]="language"></app-menu-link>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="small-12 columns text-center social">
    <ul>
      <li><a [routerLink]="'/redes'" [fragment]="'facebook'" aria-label="Banrepcultural en Facebook"><i class="icon-facebook"></i></a></li>
      <li><a [routerLink]="'/redes'" [fragment]="'youtube'" aria-label="Banrepcultural en YouTube"><i class="icon-youtube-play"></i></a></li>
      <li><a [routerLink]="'/redes'" [fragment]="'instagram'" aria-label="Banrepcultural en Instagram"><i class="icon-instagram"></i></a></li>
      <li><a [routerLink]="'/redes'" [fragment]="'twitter'" aria-label="Banrepcultural en Twitter"><i class="icon-twitter"></i></a></li>
    </ul>
  </div>
</div>
<div *ngIf="ready && isMobile" class="small-12 columns temp-menu-item"></div>

<ng-template #searchMenu>
  <div id="seeker-main-menu" [ngClass]="searchActive ? 'active' : ''">
    <div id="hlmenuv2" class="medium-12 columns">
      <div class="bkgdgmm">
        <div class="search">
          <div id="searchSmall" class="medium-12 active">
            <div id="seeker-menu">
              <app-seeker [home]="'buscador-movil'" [language]="language"></app-seeker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>