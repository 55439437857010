import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonFunctions } from '../../app.common';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  public endpoint: string;
  public endpointmenu: string;

  constructor( private http: HttpClient, private commonFunctions: CommonFunctions ) {
    this.endpoint = environment.endpointApi + 'noticias/anuncio';
    this.endpointmenu = environment.endpointApi + 'brc/menu?';
  }
  
  getInfo(): Observable<any> {
    return this.http.get(this.endpoint);
  }

  getData(params: any): Observable<any> {
    return this.http.get(this.endpointmenu + this.commonFunctions.paramsEndPoint(params));
  }
}
