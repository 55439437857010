<ng-container *ngIf="!mobile">
	<ng-container *ngIf="item?.class !== 'container'">
		<ng-container *ngIf="item?.title">
			<span class="title {{item?.class}}">
				<ng-container *ngIf="language === 'es'">{{item?.name}}</ng-container><ng-container *ngIf="language === 'en'">{{item?.name_en ? item?.name_en : item?.name}}</ng-container>
			</span>
		</ng-container>
		<ng-container *ngIf="!item?.title">
			<ng-container *ngIf="!item?.fragment && !item?.queryParams">
				<ng-container *ngIf="item?.image">
					<div [class]="item?.class">
					  	<a [brcLink]="item?.path" [target]="item?.target" [title]="item?.name" [role]="role" [aria-label]="item?.name" [class]="class">
							<ng-container *ngIf="language === 'es'">
								<ng-container *ngIf="item?.url_image">
									<img  src="{{ item?.url_image }}" alt="{{item?.name}}"> 
								</ng-container>
								{{item?.name}} 
							</ng-container>
							<ng-container *ngIf="language === 'en'">
								<p>{{item?.name_en ? item?.name_en : item?.name}}</p>
							</ng-container>
						</a>
						<ng-container *ngIf="item?.description">
							<span>{{item?.description}}</span>
						</ng-container>
					</div>
				</ng-container>	
				<ng-container *ngIf="!item?.image">
				  	<a [brcLink]="item?.path" [target]="item?.target" [title]="item?.name" [role]="role" [aria-label]="item?.name" [class]="item?.class + ' ' + class">
						<ng-container *ngIf="language === 'es'">
							<ng-container *ngIf="item?.url_image">
								<img  src="{{ item?.url_image }}" alt="{{item?.name}}"> 
							</ng-container>
							{{item?.name}} 
						</ng-container>
						<ng-container *ngIf="language === 'en'">
							{{item?.name_en ? item?.name_en : item?.name}}
						</ng-container>
					</a>
					<ng-container *ngIf="item?.description">
						<span>{{item?.description}}</span>
					</ng-container>
				</ng-container>
			</ng-container>
			
			<ng-container *ngIf="item?.fragment && !item?.queryParams">
				<ng-container *ngIf="item?.image">
					<div [class]="item?.class">
					 	<a [brcLink]="item?.path" [fragment]="item?.fragment" [target]="item?.target" [title]="item?.name" [role]="role" [aria-label]="item?.name" [class]="class">
							<ng-container *ngIf="language === 'es'">
								<ng-container *ngIf="item?.url_image">
									<img  src="{{ item?.url_image }}" alt="{{item?.name}}"> 
								</ng-container>
								{{item?.name}} 
							</ng-container>
							<ng-container *ngIf="language === 'en'">
								{{item?.name_en ? item?.name_en : item?.name}}
							</ng-container>
						</a>
						<ng-container *ngIf="item?.description">
							<span>{{item?.description}}</span>
						</ng-container>
					</div>
				</ng-container>
				<ng-container *ngIf="!item?.image">
				  	<a [brcLink]="item?.path" [fragment]="item?.fragment" [target]="item?.target" [title]="item?.name" [role]="role" [aria-label]="item?.name" [class]="item?.class + ' ' + class">
						<ng-container *ngIf="language === 'es'">
							<ng-container *ngIf="item?.url_image">
								<img  src="{{ item?.url_image }}" alt="{{item?.name}}"> 
							</ng-container>
							{{item?.name}} 
						</ng-container>
						<ng-container *ngIf="language === 'en'">
							{{item?.name_en ? item?.name_en : item?.name}}
						</ng-container>
					</a>
					<ng-container *ngIf="item?.description">
						<span>{{item?.description}}</span>
					</ng-container>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="!item?.fragment && item?.queryParams">
				<ng-container *ngIf="item?.image">
					<div [class]="item?.class">
					<a [brcLink]="item?.path" [queryParams]="item?.queryParams" [target]="item?.target" [title]="item?.name" [role]="role" [aria-label]="item?.name" [class]="class">
						<ng-container *ngIf="language === 'es'">
							<ng-container *ngIf="item?.url_image">
								<img  src="{{ item?.url_image }}" alt="{{item?.name}}"> 
							</ng-container>
							{{item?.name}} 
						</ng-container>
						<ng-container *ngIf="language === 'en'">
							<p>{{item?.name_en ? item?.name_en : item?.name}}</p>
						</ng-container>
					</a>
					<ng-container *ngIf="item?.description">
						<span>{{item?.description}}</span>
					</ng-container>
					</div>
				</ng-container>
				<ng-container *ngIf="!item?.image">
				  	<a [brcLink]="item?.path" [queryParams]="item?.queryParams" [target]="item?.target" [title]="item?.name" [role]="role" [aria-label]="item?.name" [class]="item?.class + ' ' + class">
						<ng-container *ngIf="language === 'es'">
							<ng-container *ngIf="item?.url_image">
								<img  src="{{ item?.url_image }}" alt="{{item?.name}}"> 
							</ng-container>
							{{item?.name}} 
						</ng-container>
						<ng-container *ngIf="language === 'en'">
							{{item?.name_en ? item?.name_en : item?.name}}
						</ng-container>
					</a>
					<ng-container *ngIf="item?.description">
						<span>{{item?.description}} </span>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>
<ng-container *ngIf="mobile">
	<ng-container *ngIf="!item?.title">
		<ng-container *ngIf="item?.class !== 'container'">
			<ng-container *ngIf="!item?.fragment && !item?.queryParams">
				<a [brcLink]="item?.path" [target]="item?.target" [title]="item?.name" [role]="role" [aria-label]="item?.name">
				  <ng-container *ngIf="language === 'es'">{{item?.name}}</ng-container><ng-container *ngIf="language === 'en'">{{item?.name_en ? item?.name_en : item?.name}}</ng-container><ng-container *ngIf="item?.description">/ {{item?.description}}</ng-container>
				</a>
			</ng-container>
			<ng-container *ngIf="item?.fragment">
				<a [brcLink]="item?.path" [fragment]="item?.fragment" [target]="item?.target" [title]="item?.name" [role]="role" [aria-label]="item?.name">
				  <ng-container *ngIf="language === 'es'">{{item?.name}}</ng-container><ng-container *ngIf="language === 'en'">{{item?.name_en ? item?.name_en : item?.name}}</ng-container><ng-container *ngIf="item?.description">/ {{item?.description}}</ng-container>
				</a>
			</ng-container>
			<ng-container *ngIf="item?.queryParams">
				<a [brcLink]="item?.path" [queryParams]="item?.queryParams" [target]="item?.target" [title]="item?.name" [role]="role" [aria-label]="item?.name">
					<ng-container *ngIf="language === 'es'">{{item?.name}}</ng-container><ng-container *ngIf="language === 'en'">{{item?.name_en ? item?.name_en : item?.name}}</ng-container><ng-container *ngIf="item?.description">/ {{item?.description}}</ng-container>
				</a>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>
