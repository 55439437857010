import { Component, OnInit } from '@angular/core';
import { FooterService } from './footer.service';
import { translation } from '../../../assets/language/translation';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']

})
export class FooterComponent implements OnInit {

  public env: any;
  public menu: any;
  public ready: boolean = false;
  public trans: any = translation;
  public language: string = 'es';
  public museumCoin: boolean = false
  constructor(public Service: FooterService) { }

  ngOnInit(): void {
    this.env = environment;

    let pathname = window.location.pathname, hash = window.location.hash;
    if(pathname === '/en') {
      this.language = 'en';
    }

    this.Service.getData({name: 'footer'})
    .subscribe(
      data => {
        data.forEach(function(v: any, k: number) {
          if(v.childs){
            data[k].childs = v.childs.sort((a: any, b: any) => a['weight']-b['weight'])
          }
        });
        this.menu = data.sort((a: any, b: any) => a['weight']-b['weight'])
      },
      err  => console.log(),
      () => this.ready = true
    );

    $('app-footer').foundation();
  }
}
