import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized } from '@angular/router';
import { HeaderService } from './header.service';
import { CommonFunctions } from '../../app.common';
import { environment } from '../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewChecked {

  public url = '';
  public hash = 0;
  public foundation: boolean = false;
  public show: boolean = false;
  public active: boolean = false;
  public title: any;
  public message: any;
  public results: boolean = false;
  public isMobile: boolean = false;
  public routeData: any;
  public hidesecondarymenu: boolean = false;
  public menu: any;
  public env: any;
  public ready: boolean = false;
  public language: string = 'es';
  public vh: number;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public Service: HeaderService, private commonFunctions: CommonFunctions) { }

  ngOnInit(): void {
    this.env = environment;
    this.isMobile = this.commonFunctions.isMobile();

    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        $('#skip-main-content').attr('data-keyindex', 0);
        let pathname = window.location.pathname, hash = window.location.hash;
        if(pathname === '' || pathname === '/') {
          this.url = '/#main-content';
        }else {
          this.url = pathname+'#main-content';
        }
        if(hash === ''){
          $('#skip-main-content').attr('data-keyindex', 0);
        }else {
          $('#skip-main-content').attr('data-keyindex', val.id);
        }

        if(pathname === '/en') {
          this.language = 'en';
        }
        const routeData = this.activatedRoute.snapshot.firstChild?.data;
        this.vh = routeData.vh;
        this.processHeader(this.vh);

        if(val.url.indexOf('/narrativas-digitales/') > -1) {
          this.hidesecondarymenu = true;
        }else {
          this.hidesecondarymenu = false;
        }
      }
    });

    $('html').keydown(function (e:any) {
      var datakeyindex = parseInt($('#skip-main-content').attr('data-keyindex')), datakeyhash = parseInt($('#skip-main-content').attr('data-keyhash'));
      if(e.keyCode == 13 || e.keyCode == 32) {//Enter or Space
        if( datakeyindex === 1 ){
          setTimeout(function(){
            $('#skip-main-content').attr('data-keyindex', 2);
            $('html,body').animate({scrollTop: $('#main-content').offset().top }, 500);
            $('#main-content').blur().focus();
          }, 0);
        }
      }
      if(e.keyCode == 9 || e.keyCode == 37 || e.keyCode == 38 || e.keyCode == 39 || e.keyCode == 40) {// Tab or Arrows
        if( datakeyindex === 0 ){
          setTimeout(function(){ $('#skip-main-content').removeClass('hide').click().focus(); }, 0);
          datakeyindex++;
          $('#skip-main-content').attr('data-keyindex', datakeyindex);
        }else {
          datakeyindex++;
          $('#skip-main-content').attr('data-keyindex', datakeyindex);
          $('#skip-main-content').addClass('hide');
         }
      }
    });
  }

  processHeader(vh) {
    if(vh > 1){
      this.ready = true;
    }else {
      this.Service.getData({name: 'secondary-menu-front'})
      .subscribe(
        data => {
          data.forEach(function(v:any, k:number) {
            if(v.childs){
              data[k].childs = v.childs.sort((a:any, b:any) => a['weight']-b['weight'])
            }
          });
          this.menu = data.sort((a:any, b:any) => a['weight']-b['weight']);
        },
        err  => console.log(),
        () => this.ready = true
      );

      this.Service.getInfo()
      .subscribe(
        data => {
          this.title = (typeof data.nodes[0] != 'undefined') ? (typeof data.nodes[0].title != 'undefined') ? data.nodes[0].title : 'Notificaciones' : 'Notificaciones';
          this.message = (typeof data.nodes[0] != 'undefined') ? (typeof data.nodes[0].body != 'undefined') ? data.nodes[0].body : 'undefined' : 'undefined';
        },
        err => console.log(),
        () =>  { this.active = (this.message != 'undefined') ? true : false; this.results = (this.message != 'undefined') ? true : false; },
      );
    }
  }

  ngAfterViewChecked(): void {
    if (!this.foundation && this.ready || !this.foundation && this.results) {
      this.foundation = true;
      $('app-header').foundation();
    }
  }

  openMessage(){
    this.show = this.show ? false : true;
  }

  closeMessage(){
    this.show = false;
  }
}
