import { UrlSegment } from '@angular/router';

export function MinisiteFrontMatch(url: UrlSegment[]) {
  const regex = /(proyectos|exposiciones|programas|narrativas-digitales)/gm
  if ( url.length == 2 && regex.exec(url[0].path) !== null  ) {
    return { consumed: url, posParams:{ space: url[0], path:url[1] } }
  }
  return null;
}

export function MinisiteFatherOrChildMatch(url: UrlSegment[]) {
  const regex = /(proyectos|exposiciones|programas|narrativas-digitales)/gm;
  if ( url.length == 3 && regex.exec(url[0].path) !== null  ) {
    return { consumed: url, posParams:{ space: url[0], path:url[1], father:url[2] } }
  }
  if ( url.length == 4 && regex.exec(url[0].path) !== null  ) {
    return { consumed: url, posParams:{ space: url[0], path:url[1], father:url[2], title:url[3] } }
  }
  if ( url.length == 5 && regex.exec(url[0].path) !== null  ) {
    return { consumed: url, posParams:{ space:url[0], path:url[1], father:url[2], child:url[3], title:url[4] } }
  }
  return null;
}

export function HistoryCredential( url: UrlSegment[] ){
  const regex = [/biblioteca-virtual/gm, /credencial-historia/gm];
  return null
}

export function HomeLayoutv4(url: UrlSegment[]) {
  if (url.length > 2 &&  url[0].path === 'bogota' && url[1].path === 'casa-de-moneda') {
    return { consumed: url, posParams:{ path:url[0], father:url[1], child:url[2] } }
  }
  if (url.length > 2 &&  url[0].path === 'bogota' && url[1].path === 'museo-botero') {
    return { consumed: url, posParams:{ path:url[0], father:url[1], child:url[2] } }
  }
  if (url.length > 2 &&  url[0].path === 'bogota' && url[1].path === 'museo-de-arte') {
    return { consumed: url, posParams:{ path:url[0], father:url[1], child:url[2] } }
  }
  if (url.length > 2 &&  url[0].path === 'bogota' && url[1].path === 'casa-gomez-campuzano') {
    return { consumed: url, posParams:{ path:url[0], father:url[1], child:url[2] } }
  }
  return null;
}