import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsGuard implements CanActivate, OnDestroy {
  private navEndSub: Subscription;

  constructor(private router: Router) {
    this.navEndSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      pairwise()
    ).subscribe(([previous, current]: [NavigationEnd, NavigationEnd]) => {
      this.handleQueryParamsChange(previous, current);
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  private handleQueryParamsChange(previous: NavigationEnd, current: NavigationEnd): void {
    const previousUrlTree = this.router.parseUrl(previous.urlAfterRedirects);
    const currentUrlTree = this.router.parseUrl(current.urlAfterRedirects);

    const previousUrlWithoutParams = previousUrlTree.root.children.primary?.segments.map(it => it.path).join('/') || '';
    const currentUrlWithoutParams = currentUrlTree.root.children.primary?.segments.map(it => it.path).join('/') || '';

    if (previousUrlWithoutParams === currentUrlWithoutParams && JSON.stringify(previousUrlTree.queryParams) !== JSON.stringify(currentUrlTree.queryParams)) {
      this.router.navigateByUrl('/reload', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrlWithoutParams], {
          queryParams: currentUrlTree.queryParams,
          queryParamsHandling: 'merge',
          fragment: currentUrlTree.fragment
        });
      });
    }
  }

  ngOnDestroy(): void {
    if (this.navEndSub) {
      this.navEndSub.unsubscribe();
    }
  }
}
