import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MinisiteFrontMatch, MinisiteFatherOrChildMatch, HomeLayoutv4 } from './app-router.functions'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { externalRedirectGuard } from './services/external-redirect.guard';
import { QueryParamsGuard } from './services/query-params.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data: { home: 'banrepcultural', breadcrumb: false }
  },
  {
    path: 'bibliotecas',
    loadChildren: () => import('./home-libraries/home-libraries.module').then(m => m.HomeLibrariesModule),
    pathMatch: 'full',
    data: {  home: 'biblioteca', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'centros-culturales',
    loadChildren: () => import('./home-libraries/home-libraries.module').then(m => m.HomeLibrariesModule),
    pathMatch: 'full',
    data: {  home: 'centros-culturales', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'museos',
    loadChildren: () => import('./home-libraries/home-libraries.module').then(m => m.HomeLibrariesModule),
    pathMatch: 'full',
    data: { home: 'museos', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'armenia',
    redirectTo: '/armenia/museo-del-oro-quimbaya',
    pathMatch: 'full'
  },
  {
    path: 'armenia/biblioteca',
    redirectTo: '/armenia/museo-del-oro-quimbaya',
    pathMatch: 'full'
  },
  {
    path: 'armenia/centro-de-documentacion-regional',
    redirectTo: '/armenia/museo-del-oro-quimbaya',
    pathMatch: 'full'
  },
  {
    path: 'armenia/museo-del-oro-quimbaya',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city:'armenia', place:'museo-del-oro-quimbaya', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'armenia/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'armenia', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'barranquilla',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'barranquilla', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'barranquilla/biblioteca',
    redirectTo: '/barranquilla',
    pathMatch: 'full'
  },
  {
    path: 'barranquilla/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'barranquilla', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'bogota',
    loadChildren: () => import('./home-libraries/home-libraries.module').then(m => m.HomeLibrariesModule),
    pathMatch: 'full',
    data: { home: 'bogota', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'bogota/biblioteca',
    redirectTo: '/bogota/biblioteca-luis-angel-arango',
    pathMatch: 'full',
  },
  {
    path: 'bogota/museo-del-oro',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 2, city: 'bogota', place: 'museo-del-oro', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'bogota/museo-de-arte',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 2, city: 'bogota', place: 'museo-de-arte', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'bogota/casa-de-moneda',
    loadChildren: () => import('./home-coin-museum/home-coin-museum.module').then(m => m.HomeCoinMuseumModule),
    pathMatch: 'full',
    data: { vh: 2, breadcrumb: true, alwaysRefresh: true },
    canActivate: [QueryParamsGuard],
  },
  {
    path: 'bogota/casa-de-moneda/:title',
    loadChildren: () => import('./home-coin-museum/home-coin-museum.module').then(m => m.HomeCoinMuseumModule),
    pathMatch: 'full',
    data: { vh: 2, breadcrumb: true, alwaysRefresh: true },
    canActivate: [QueryParamsGuard],
  },
  {
    path: 'bogota/casa-de-moneda/:title/:path',
    loadChildren: () => import('./home-coin-museum/home-coin-museum.module').then(m => m.HomeCoinMuseumModule),
    pathMatch: 'full',
    data: { vh: 2, breadcrumb: true, alwaysRefresh: true },
    canActivate: [QueryParamsGuard],
  },
  {
    path: 'bogota/casa-de-moneda/:title/:father/:path',
    loadChildren: () => import('./home-coin-museum/home-coin-museum.module').then(m => m.HomeCoinMuseumModule),
    pathMatch: 'full',
    data: { vh: 2, breadcrumb: true, alwaysRefresh: true },
    canActivate: [QueryParamsGuard],
  },
  {
    path: 'bogota/museo-botero',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 2, city: 'bogota', place: 'museo-botero', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'bogota/casa-gomez-campuzano',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 2, city: 'bogota', place: 'casa-gomez-campuzano', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'bogota/biblioteca-luis-angel-arango',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'bogota', place: 'biblioteca-luis-angel-arango', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'bogota/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'bogota', breadcrumb: true, alwaysRefresh: true }
  },
    {
    path: 'bogota/:path/:father/:child',
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    pathMatch: 'full',
    data: { city: 'basic-page', space: 'bogota', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'bogota/:path/:father/:child/:title',
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    pathMatch: 'full',
    data: { city: 'basic-page', space: 'bogota', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'bogota/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'bogota', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'bucaramanga',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'bucaramanga', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'bucaramanga/biblioteca',
    redirectTo: '/bucaramanga',
    pathMatch: 'full'
  },
  {
    path: 'bucaramanga/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'bucaramanga', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'buenaventura',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'buenaventura', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'buenaventura/biblioteca',
    redirectTo: '/buenaventura',
    pathMatch: 'full'
  },
  {
    path: 'buenaventura/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'buenaventura', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'cali',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'cali', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'cali/biblioteca',
    redirectTo: '/cali',
    pathMatch: 'full'
  },
  {
    path: 'cali/centro-de-documentacion-regional',
    redirectTo: '/cali',
    pathMatch: 'full'
  },
  {
    path: 'cali/museo-del-oro-calima',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 2, city:'cali', place:'museo-del-oro-calima', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'cali/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'cali', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'cartagena',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'cartagena', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'cartagena/biblioteca',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { city: 'cartagena', place: 'biblioteca', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'cartagena/museo-del-oro-zenu',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 2, city:'cartagena', place:'museo-del-oro-zenu', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'cartagena/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'cartagena', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'cucuta',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'cucuta', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'cucuta/biblioteca',
    redirectTo: '/cucuta',
    pathMatch: 'full'
  },
  {
    path: 'cucuta/centro-de-documentacion-regional',
    redirectTo: '/cucuta',
    pathMatch: 'full'
  },
  {
    path: 'cucuta/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'cucuta', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'florencia',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'florencia', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'florencia/biblioteca',
    redirectTo: '/florencia',
    pathMatch: 'full'
  },
  {
    path: 'florencia/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'florencia', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'girardot',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'girardot', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'girardot/biblioteca',
    redirectTo: '/girardot',
    pathMatch: 'full'
  },
  {
    path: 'girardot/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'girardot', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'honda',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'honda', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'honda/biblioteca',
    redirectTo: '/honda',
    pathMatch: 'full'
  },
  {
    path: 'honda/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'honda', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'ibague',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'ibague', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'ibague/biblioteca',
    redirectTo: '/ibague',
    pathMatch: 'full'
  },
  {
    path: 'ibague/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'ibague', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'ipiales',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'ipiales', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'ipiales/biblioteca',
    redirectTo: '/ipiales',
    pathMatch: 'full'
  },
  {
    path: 'ipiales/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'ipiales', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'leticia',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'leticia', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'leticia/biblioteca',
    redirectTo: '/leticia',
    pathMatch: 'full'
  },
  {
    path: 'leticia/museo-etnografico',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 2, city:'leticia', place:'museo-etnografico', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'leticia/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'leticia', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'manizales',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'manizales', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'manizales/biblioteca',
    redirectTo: '/manizales',
    pathMatch: 'full'
  },
  {
    path: 'manizales/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'manizales', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'medellin',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'medellin', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'medellin/biblioteca',
    redirectTo: '/medellin',
    pathMatch: 'full'
  },
  {
    path: 'medellin/centro-de-documentacion-regional',
    redirectTo: '/medellin',
    pathMatch: 'full'
  },
  {
    path: 'medellin/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'medellin', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'monteria',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'monteria', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'monteria/biblioteca',
    redirectTo: '/monteria',
    pathMatch: 'full'
  },
  {
    path: 'monteria/centro-de-documentacion-regional',
    redirectTo: '/monteria',
    pathMatch: 'full'
  },
  {
    path: 'monteria/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'monteria', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'neiva',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'neiva', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'neiva/biblioteca',
    redirectTo: '/neiva',
    pathMatch: 'full'
  },
  {
    path: 'neiva/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'neiva', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'pasto',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'pasto', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'pasto/biblioteca',
    redirectTo: '/pasto',
    pathMatch: 'full'
  },
  {
    path: 'pasto/museo-del-oro-nariño',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 2, city: 'pasto', place: 'museo-del-oro-nariño', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'pasto/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'pasto', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'pereira',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'pereira', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'pereira/biblioteca',
    redirectTo: '/pereira',
    pathMatch: 'full'
  },
  {
    path: 'pereira/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'pereira', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'popayan',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'popayan', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'popayan/biblioteca',
    redirectTo: '/popayan',
    pathMatch: 'full'
  },
  {
    path: 'popayan/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'popayan', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'quibdo',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'quibdo', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'quibdo/biblioteca',
    redirectTo: '/quibdo',
    pathMatch: 'full'
  },
  {
    path: 'quibdo/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'quibdo', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'riohacha',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'riohacha', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'riohacha/biblioteca',
    redirectTo: '/riohacha',
    pathMatch: 'full'
  },
  {
    path: 'riohacha/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'riohacha', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'san-andres',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'san-andres', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'san-andres/biblioteca',
    redirectTo: '/san-andres',
    pathMatch: 'full'
  },
  {
    path: 'san-andres/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'san-andres', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'santa-marta',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'santa-marta', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'santa-marta/biblioteca',
    redirectTo: '/santa-marta',
    pathMatch: 'full'
  },
  {
    path: 'santa-marta/museo-del-oro-tairona',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 2, city: 'santa-marta', place: 'museo-del-oro-tairona', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'santa-marta/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'santa-marta', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'sincelejo',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'sincelejo', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'sincelejo/biblioteca',
    redirectTo: '/sincelejo',
    pathMatch: 'full'
  },
  {
    path: 'sincelejo/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'sincelejo', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'tunja',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'tunja', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'tunja/biblioteca',
    redirectTo: '/tunja',
    pathMatch: 'full'
  },
  {
    path: 'tunja/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'tunja', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'valledupar',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'valledupar', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'valledupar/biblioteca',
    redirectTo: '/valledupar',
    pathMatch: 'full'
  },
  {
    path: 'valledupar/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'valledupar', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'villavicencio',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 3, city: 'villavicencio', place: 'centro-cultural', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'villavicencio/biblioteca',
    redirectTo: '/villavicencio',
    pathMatch: 'full'
  },
  {
    path: 'villavicencio/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'villavicencio', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'en-linea/actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { city: 'en-linea', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: ':city/actividad-musical',
    redirectTo: '/actividad-musical',
    pathMatch: 'full',
  },
  {
    path: 'actividad-musical',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 2, place: 'actividad-musical', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'biblioteca-virtual',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { vh: 2, place: 'biblioteca-virtual', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'blaavirtual',
    redirectTo: '/biblioteca-virtual',
    pathMatch: 'full'
  },
  {
    path: 'biblioteca-virtual/credencial-historia',
    loadChildren: () => import('./home-credential/home-credential.module').then(m => m.HomeCredentialModule),
    pathMatch: 'full',
    data: { city: 'biblioteca-virtual', breadcrumb: true }
  },
  {
    path: 'biblioteca-virtual/credencial-historia/acerca-de',
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    pathMatch: 'full',
    data: { space: 'biblioteca-virtual/credencial-historia/acerca-de', breadcrumb: true }
  },
  {
    path: 'biblioteca-virtual/credencial-historia/:path',
    loadChildren: () => import('./mega-template/mega-template.module').then(m => m.MegaTemplateModule),
    pathMatch: 'full',
    data: { space: 'biblioteca-virtual/credencial-historia', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'biblioteca-virtual/credencial-historia/:path/:father',
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    pathMatch: 'full',
    data: { space: 'biblioteca-virtual/credencial-historia', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'biblioteca-virtual/credencial-historia/:path/:father/:child',
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    pathMatch: 'full',
    data: { space: 'biblioteca-virtual/credencial-historia', alwaysRefresh: true }
  },
  {
    path: 'biblioteca-virtual/recursos-electronicos',
    loadChildren: () => import('./home-electronic-resources/home-electronic-resources.module').then(m => m.HomeElectronicResourcesModule),
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'biblioteca-virtual/recursos-electronicos/acerca-de',
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    pathMatch: 'full',
    data: { space: 'biblioteca-virtual/recursos-electronicos/acerca-de', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'biblioteca-virtual/recursos-electronicos/como-ingresar',
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    pathMatch: 'full',
    data: { space: 'biblioteca-virtual/recursos-electronicos/como-ingresar', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'biblioteca-virtual/recursos-electronicos/:path',
    loadChildren: () => import('./electronic-resource/electronic-resource.module').then(m => m.ElectronicResourceModule),
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'biblioteca-virtual/:path',
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    pathMatch: 'full',
    data: { space: 'biblioteca-virtual', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'biblioteca-virtual/:path/:father',
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    pathMatch: 'full',
    data: { space: 'biblioteca-virtual', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'biblioteca-virtual/:path/:father/:child',
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    pathMatch: 'full',
    data: { space: 'biblioteca-virtual', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'ninos-y-ninas',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule),
    pathMatch: 'full',
    data: { place: 'ninos-y-ninas', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'actividades',
    loadChildren: () => import('./home-activities/home-activities.module').then(m => m.HomeActivitiesModule),
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true },
    canActivate: [QueryParamsGuard], 
  },
  {
    path: 'actividades/exposiciones',
    redirectTo: '/actividades',
    pathMatch: 'full'
  },
  {
  path: 'actividades/exposiciones/:city',
    redirectTo: '/actividades',
    pathMatch: 'full'
  },
    {
    path: 'actividades/conferencias',
    redirectTo: '/actividades',
    pathMatch: 'full'
  },
  {
    path: 'actividades/conferencias/:city',
    redirectTo: '/actividades',
    pathMatch: 'full'
  },
  {
    path: 'actividades/talleres',
    redirectTo: '/actividades',
    pathMatch: 'full'
  },
  {
    path: 'actividades/talleres/:city',
    redirectTo: '/actividades',
    pathMatch: 'full'
  },
  {
    path: 'actividades/conciertos',
    redirectTo: '/actividades',
    pathMatch: 'full'
  },
  {
    path: 'actividades/conciertos/:city',
    redirectTo: '/actividades',
    pathMatch: 'full'
  },
  {
    path: 'actividades/:city',
    loadChildren: () => import('./home-activities/home-activities.module').then(m => m.HomeActivitiesModule),
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'actividad/:title',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: ':city/actividad',
    redirectTo: '/actividades/:city',
    pathMatch: 'full'
  },
  {
    path: 'noticias',
    loadChildren: () => import('./home-news/home-news.module').then(m => m.HomeNewsModule),
    pathMatch: 'full'
  },
  {
    path: 'noticias/:path',
    loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'publicaciones',
    /*loadChildren: () => import('./home-publications/home-publications.module').then(m => m.HomePublicationsModule),*/
    children: [],
    canActivate: [ externalRedirectGuard ],
    pathMatch: 'full'
  },
  {
    path: 'publicaciones/catalogo',
    /*loadChildren: () => import('./home-publications/home-publications.module').then(m => m.HomePublicationsModule),*/
    children: [],
    canActivate: [ externalRedirectGuard ],
    pathMatch: 'full',
    data: { category: 'Catálogo', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'publicaciones/libro',
    /*loadChildren: () => import('./home-publications/home-publications.module').then(m => m.HomePublicationsModule),*/
    children: [],
    canActivate: [ externalRedirectGuard ],
    pathMatch: 'full',
    data: { category: 'Libro', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'publicaciones/folleto',
    /*loadChildren: () => import('./home-publications/home-publications.module').then(m => m.HomePublicationsModule),*/
    children: [],
    canActivate: [ externalRedirectGuard ],
    pathMatch: 'full',
    data: { category: 'Folleto', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'publicaciones/disco',
    /*loadChildren: () => import('./home-publications/home-publications.module').then(m => m.HomePublicationsModule),*/
    children: [],
    canActivate: [ externalRedirectGuard ],
    pathMatch: 'full',
    data: { category: 'Disco', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'publicaciones/revista',
    /*loadChildren: () => import('./home-publications/home-publications.module').then(m => m.HomePublicationsModule),*/
    children: [],
    canActivate: [ externalRedirectGuard ],
    pathMatch: 'full',
    data: { category: 'Revista', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'publicaciones/juegos',
    /*loadChildren: () => import('./home-publications/home-publications.module').then(m => m.HomePublicationsModule),*/
    children: [],
    canActivate: [ externalRedirectGuard ],
    pathMatch: 'full',
    data: { category: 'Juegos', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'publicaciones/:path',
    /*loadChildren: () => import('./publication/publication.module').then(m => m.PublicationModule),*/
    children: [],
    canActivate: [ externalRedirectGuard ],
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'prensa',
    loadChildren: () => import('./home-press/home-press.module').then(m => m.HomePressModule),
    pathMatch: 'full',
    data: { path: 'prensa' }
  },
  {
    path: 'prensa/historico-boletines',
    loadChildren: () => import('./press/press.module').then(m => m.PressModule),
    pathMatch: 'full',
    data: { path: 'prensa', father: 'historico-boletines' }
  },
  {
    path: 'coleccion-bibliografica/especiales',
    loadChildren: () => import('./home-collection-bibliographic/home-collection-bibliographic.module').then(m => m.HomeCollectionBibliographicModule),
    pathMatch: 'full',
    data: { path: 'coleccion-bibliografica', father: 'especiales', breadcrumb: true }
  },
  {
    path: 'coleccion-bibliografica/especiales/acercade',
    loadChildren: () => import('./collection-internal/collection-internal.module').then(m => m.CollectionInternalModule),
    pathMatch: 'full',
    data: { path: 'coleccion-bibliografica', father: 'especiales', title: 'acercade', breadcrumb: true }
  },
  {
    path: 'coleccion-bibliografica/especiales/temas',
    redirectTo: '/coleccion-bibliografica/especiales',
    pathMatch: 'full'
  },
  {
    path: 'coleccion-bibliografica/especiales/tipos',
    redirectTo: '/coleccion-bibliografica/especiales',
    pathMatch: 'full'
  },
  {
    path: 'coleccion-bibliografica/especiales/:title',
    loadChildren: () => import('./collections/collections.module').then(m => m.CollectionsModule),
    pathMatch: 'full',
    data: { path: 'coleccion-bibliografica', father: 'especiales', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'coleccion-bibliografica/especiales/:child/:title',
    loadChildren: () => import('./collection-internal/collection-internal.module').then(m => m.CollectionInternalModule), 
    pathMatch: 'full',
    data: { path: 'coleccion-bibliografica', father: 'especiales', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'coleccion-de-arte',
    loadChildren: () => import('./home-collections/home-collections.module').then(m => m.HomeCollectionsModule),
    pathMatch: 'full',
    data: { path: 'coleccion-de-arte', breadcrumb: true }
  },
  {
    path: 'coleccion-de-arte/obra',
    redirectTo: '/coleccion-de-arte',
    pathMatch: 'full'
  },
  {
    path: 'coleccion-de-arte/obra/:title',
    loadChildren: () => import('./collections/collections.module').then(m => m.CollectionsModule),
    pathMatch: 'full',
    data: { path: 'coleccion-de-arte', father: 'obra', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'coleccion-de-arte/artista',
    loadChildren: () => import('./home-collection-artist/home-collection-artist.module').then(m => m.HomeCollectionArtistModule),
    pathMatch: 'full',
    data: { path: 'coleccion-de-arte', father: 'artista', breadcrumb: true }
  },
  {
    path: 'coleccion-de-arte/artista/:title',
    loadChildren: () => import('./collection-artist/collection-artist.module').then(m => m.CollectionArtistModule),
    pathMatch: 'full',
    data: { path: 'coleccion-de-arte', father: 'artista', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'coleccion-de-arte/curaduria',
    redirectTo: '/coleccion-de-arte',
    pathMatch: 'full'
  },
  {
    path: 'coleccion-de-arte/curaduria/:title',
    loadChildren: () => import('./collection-curaduria/collection-curaduria.module').then(m => m.CollectionCuraduriaModule),
    pathMatch: 'full',
    data: { path: 'coleccion-de-arte', father: 'curaduria', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'coleccion-de-instrumentos',
    loadChildren: () => import('./home-collections/home-collections.module').then(m => m.HomeCollectionsModule),
    pathMatch: 'full',
    data: { path: 'coleccion-de-instrumentos', breadcrumb: true }
  },
  {
    path: 'coleccion-de-instrumentos/instrumento',
    redirectTo: '/coleccion-de-instrumentos',
    pathMatch: 'full'
  },
  {
    path: 'coleccion-de-instrumentos/instrumento/:title',
    loadChildren: () => import('./collections/collections.module').then(m => m.CollectionsModule),
    pathMatch: 'full',
    data: { path: 'coleccion-de-instrumentos', father: 'instrumento', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'coleccion-de-estampillas',
    loadChildren: () => import('./home-collections/home-collections.module').then(m => m.HomeCollectionsModule),
    pathMatch: 'full',
    data: { path: 'coleccion-de-estampillas', breadcrumb: true }
  },
  {
    path: 'coleccion-de-estampillas/estampilla',
    redirectTo: '/coleccion-de-estampillas',
    pathMatch: 'full'
  },
  {
    path: 'coleccion-de-estampillas/estampilla/:title',
    loadChildren: () => import('./collections/collections.module').then(m => m.CollectionsModule),
    pathMatch: 'full',
    data: { path: 'coleccion-de-estampillas', father: 'estampilla', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'coleccion-billetes-monedas',
    loadChildren: () => import('./home-collections/home-collections.module').then(m => m.HomeCollectionsModule),
    pathMatch: 'full',
    data: { path: 'coleccion-billetes-monedas', breadcrumb: true }
  },
  {
    path: 'coleccion-billetes-monedas/pieza',
    redirectTo: '/coleccion-billetes-monedas',
    pathMatch: 'full'
  },
  {
    path: 'coleccion-billetes-monedas/pieza/:title',
    loadChildren: () => import('./collections/collections.module').then(m => m.CollectionsModule),
    pathMatch: 'full',
    data: { path: 'coleccion-billetes-monedas', father: 'pieza', breadcrumb: true }
  },
  {
    path: 'coleccion-billetes-monedas/emisor',
    redirectTo: '/coleccion-billetes-monedas',
    pathMatch: 'full'
  },
  {
    path: 'coleccion-billetes-monedas/emisor/:title',
    loadChildren: () => import('./collection-artist/collection-artist.module').then(m => m.CollectionArtistModule),
    pathMatch: 'full',
    data: { path: 'coleccion-billetes-monedas', father: 'emisor', breadcrumb: true }
  },
  /**{
    path: 'coleccion-arqueologica',
    loadChildren: () => import('./home-collections/home-collections.module').then(m => m.HomeCollectionsModule),
    pathMatch: 'full',
    data: { path: 'coleccion-arqueologica', breadcrumb: true }
  },
  {
    path: 'coleccion-arqueologica/pieza',
    redirectTo: '/coleccion-arqueologica',
    pathMatch: 'full'
  },
  {
    path: 'coleccion-arqueologica/pieza/:title',
    loadChildren: () => import('./collections/collections.module').then(m => m.CollectionsModule),
    pathMatch: 'full',
    data: { path: 'coleccion-arqueologica', father: 'pieza', breadcrumb: true }
  },**/
  {
    path: 'multimedia',
    loadChildren: () => import('./home-multimedia/home-multimedia.module').then(m => m.HomeMultimediaModule),
    pathMatch: 'full',
    data: { breadcrumb: true }
  },
  {
    path: 'multimedia/:path',
    loadChildren: () => import('./multimedia/multimedia.module').then(m => m.MultimediaModule),
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'servicios',
    loadChildren: () => import('./home-services/home-services.module').then(m => m.HomeServicesModule),
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'servicios/asociacion',
    redirectTo: '/asociacion',
    pathMatch: 'full'
  },
  {
    path: 'servicios/publico-objetivo',
    redirectTo: '/servicios',
    pathMatch: 'full'
  },
  {
    path: 'servicios/tipo-de-servicio',
    redirectTo: '/servicios',
    pathMatch: 'full'
  },
  {
    path: 'servicios/categoria',
    redirectTo: '/servicios',
    pathMatch: 'full'
  },
  {
    path: 'servicios/categoria/generales',
    redirectTo: '/servicios',
    pathMatch: 'full'
  },
  {
    path: 'servicios/categoria/publicos',
    redirectTo: '/servicios',
    pathMatch: 'full'
  },
  {
    path: 'servicios/categoria/culturales',
    redirectTo: '/servicios',
    pathMatch: 'full'
  },
  {
    path: 'servicios/catalogo-BLAA',
    redirectTo: '/servicios/descubridor',
    pathMatch: 'full'
  },
  {
    path: 'servicios/:title',
    loadChildren: () => import('./service/service.module').then(m => m.ServiceModule),
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'servicios/:father/:title',
    loadChildren: () => import('./service/service.module').then(m => m.ServiceModule),
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'servicios/:father/:child/:title',
    loadChildren: () => import('./service/service.module').then(m => m.ServiceModule),
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'servicios/:section/servicios/:title',
    redirectTo: '/servicios/:title',
    pathMatch: 'full',
  },
  {
    path: 'redes',
    loadChildren: () => import('./home-networks/home-networks.module').then(m => m.HomeNetworksModule),
    pathMatch: 'full',
    data: { breadcrumb: true }
  },
  {
    path: 'exposiciones',
    loadChildren: () => import('./home-exhibitions/home-exhibitions.module').then(m => m.HomeExhibitionsModule),
    pathMatch: 'full',
    data: { path: 'exposiciones', breadcrumb: true }
  },
  {
    path: 'programas',
    loadChildren: () => import('./home-programs/home-programs.module').then(m => m.HomeProgramsModule),
    pathMatch: 'full',
    data: { path: 'programas', breadcrumb: true }
  },
  {
    path: 'proyectos',
    loadChildren: () => import('./home-projects/home-projects.module').then(m => m.HomeProjectsModule),
    pathMatch: 'full',
    data: { path: 'proyectos', breadcrumb: true }
  },
  {
    path: 'narrativas-digitales',
    loadChildren: () => import('./home-narratives/home-narratives.module').then(m => m.HomeNarrativesModule),
    pathMatch: 'full',
    data: { path: 'narrativas-digitales', breadcrumb: true }
  },
  {
    path: 'narrativas-digitales/:path',
    loadChildren: () => import('./narratives/narratives.module').then(m => m.NarrativesModule),
    pathMatch: 'full',
    data: { type: 'father', vh: 2,  breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'narrativas-digitales/:path/:father',
    loadChildren: () => import('./narratives/narratives.module').then(m => m.NarrativesModule),
    pathMatch: 'full',
    data: { type: 'child', vh: 2,  breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'narrativas-digitales/:path/:father/:child',
    loadChildren: () => import('./narratives/narratives.module').then(m => m.NarrativesModule),
    pathMatch: 'full',
    data: { type: 'child', vh: 2,  breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'buscar',
    loadChildren: () => import('./home-search/home-search.module').then(m => m.HomeSearchModule),
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'fundaciones',
    loadChildren: () => import('./mega-template/mega-template.module').then(m => m.MegaTemplateModule),
    data: { space: 'fundaciones', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: 'en',
    loadChildren: () => import('./home-en/home-en.module').then(m => m.HomeEnModule),
    pathMatch: 'full',
    data: { language: 'en', home: true,  vh: 3, breadcrumb: false },
  },
  {
    path: 'about-banco-de-la-republicas-cultural-labor',
    redirectTo: '/en',
    pathMatch: 'full'
  },
  /**{
    path: 'about-banco-de-la-republicas-cultural-labor/gold-museum/plan-your-visit',
    redirectTo: '/en',
    pathMatch: 'full'
  },**/
  {
    path: 'exposiciones/about-banco-de-la-republicas-cultural-labor/cultural-centers-colombia/:city',
    loadChildren: () => import('./home-en/home-en.module').then(m => m.HomeEnModule),
    pathMatch: 'full',
    data: { language: 'en', home: false,  vh: 3, breadcrumb: false },
  },
  /**{
    path: 'preguntas-frecuentes',
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true }
  },**/
  {
    path: 'node/:id',
    loadChildren: () => import('./home-news/home-news.module').then(m => m.HomeNewsModule),
    pathMatch: 'full',
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    pathMatch   : 'full'
  },
  {
    matcher: HomeLayoutv4,
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    data: { vh: 4, breadcrumb: true, alwaysRefresh: true }
  },
  {
    matcher: MinisiteFrontMatch,
    loadChildren: () => import('./mega-template/mega-template.module').then(m => m.MegaTemplateModule),
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    matcher: MinisiteFatherOrChildMatch,
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    data: { breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: ':path',
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    pathMatch: 'full',
    data: { type: 'basic-page', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: ':path/:father',
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    pathMatch: 'full',
    data: { type: 'basic-page', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: ':path/:father/:child',
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    pathMatch: 'full',
    data: { type: 'basic-page', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: ':path/:father/:child/:title',
    loadChildren: () => import('./mega-content/mega-content.module').then(m => m.MegaContentModule),
    pathMatch: 'full',
    data: { type: 'basic-page', breadcrumb: true, alwaysRefresh: true }
  },
  {
    path: '**',
    pathMatch   : 'full',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {/* relativeLinkResolution: 'legacy'*/})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

/*
Vesion Header - vh
1 - Default
2 - Black
3 - White
4 - HomeLayoutv4
*/